<div class="payment-currency-alert-wrapper">
    <div class="alert d-flex align-items-center">
        <div class="alert-icon"><img [src]="networkIcon" alt="network-icon"/></div>
        <div class="alert-text">
            {{ 'payment.currency_alert' | translate : {
            currency_name: selectedPaymentOption.name,
            network_name: selectedPaymentNetwork.full_name
        } }}
        </div>
    </div>
</div>
