import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-powered-by-bitfinex",
    templateUrl: "./powered-by-bitfinex.component.html",
    styleUrls: ["./powered-by-bitfinex.component.scss"],
})
export class PoweredByBitfinexComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
