<footer>
    <div class="container">
        <span *ngIf="poweredBy" class="powered-by">{{ poweredBy }} &copy; {{ currentDate | date : 'Y' }}</span>
        <ul class="list-group list-group-horizontal">
            <li *ngIf="termsOfUse" class="list-group-item"><a [href]="termsOfUse" target="_blank"
                                                              class="btn-link-primary">{{ 'footer.terms_text' | translate }}</a>
            </li>
            <li *ngIf="privacyPolicy" class="list-group-item"><a [href]="privacyPolicy" target="_blank"
                                                              class="btn-link-primary">{{ 'footer.privacy_policy_text' | translate }}</a>
            </li>
            <li *ngIf="cookies" class="list-group-item"><a [href]="cookies" target="_blank"
                                                           class="btn-link-primary">{{ 'footer.cookies_text' | translate }}</a>
            </li>
            <li *ngIf="supportEmail" class="list-group-item"><a href="mailto:{{ supportEmail }}"
                                                                class="btn-link-primary">{{ 'footer.contact_text' | translate }}</a>
            </li>
        </ul>
    </div>
</footer>
