import { Component, Input, OnInit } from "@angular/core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-payment-loader",
    templateUrl: "./payment-loader.component.html",
    styleUrls: ["./payment-loader.component.scss"],
})
export class PaymentLoaderComponent implements OnInit {
    @Input() isShown: boolean = false;

    constructor() {}

    ngOnInit() {}
}
