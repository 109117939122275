import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface Error {
    code: string;
    to_step: string;
}

@Injectable({
    providedIn: "root",
})
export class ErrorService {
    alert$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    alertTimer: any;

    ERRORS: { [name: string]: Error } = {
        payment_amount_too_low: {
            code: "payment_amount_too_low",
            to_step: "PAYMENT_METHOD_SELECT",
        },
        create_payment_failed: {
            code: "create_payment_failed",
            to_step: "PAYMENT_METHOD_SELECT",
        },
        unknown_error: {
            code: "unknown_error",
            to_step: "PAYMENT_METHOD_SELECT",
        },
    };

    constructor() {}

    setAlert(type: string, message: string) {
        this.hideAlert();

        if (this.alert$$.value === null) {
            type = type === "error" ? "danger" : type;
            const alert = { type, message };

            // set alert
            this.alert$$.next(alert);

            // hide alert after 5 sec.
            this.alertTimer = setTimeout(() => {
                this.hideAlert();
            }, 5000);
        }
    }

    hideAlert() {
        clearTimeout(this.alertTimer);
        this.alert$$.next(null);
    }

    getErrorDetails(errorCode: string) {
        if (errorCode in this.ERRORS) {
            return this.ERRORS[errorCode];
        }

        return this.ERRORS["unknown_error"];
    }
}
