import { Component, Input, OnInit } from "@angular/core";
import { paymentSteps, StepService } from "../../../../core/services/step.service";
import { PaymentNetworkModel } from "../../../../core/models/payment-network.model";
import { PaymentService } from "../../../../core/services/payment.service";

@Component({
    selector: "app-payment-network-item",
    templateUrl: "./payment-network-item.component.html",
    styleUrls: ["./payment-network-item.component.scss"],
})
export class PaymentNetworkItemComponent implements OnInit {
    @Input() paymentNetwork: PaymentNetworkModel = new PaymentNetworkModel();
    icon = "";

    constructor(private paymentService: PaymentService, private stepService: StepService) {}

    ngOnInit() {
        this.icon = `assets/icons/payment-networks/network-${this.paymentNetwork.network_type.toLowerCase()}-primary-icon.svg`;
    }

    onClick() {
        if (this.paymentNetwork) {
            this.paymentService.selectedPaymentNetwork$$.next(this.paymentNetwork);
            if (this.paymentService.selectedPaymentOption$$.value.kyc_required) {
                this.stepService.toStep(paymentSteps.PAYMENT_KYC);
            } else {
                this.stepService.toStep(paymentSteps.PAYMENT_PROCESSOR);
            }
        }
    }
}
