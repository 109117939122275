<div class="payment-methods-title">{{ 'payment_methods.title' | translate }}</div>
<div class="payment-methods-items-wrapper">
    <div class="divider divider-light"></div>
    <app-payment-method-item *ngFor="let paymentMethod of paymentMethods"
                             [paymentMethod]="paymentMethod"></app-payment-method-item>
</div>
<!--<div class="supported-payment-methods-wrapper">-->
<!--    <button type="button" class="btn btn-link btn-link-primary"-->
<!--            (click)="openSupportedPaymentMethods()">{{ 'payment_methods.supported_payment_methods_btn' | translate }}</button>-->
<!--</div>-->
