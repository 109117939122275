<app-loader></app-loader>
<app-box-loader></app-box-loader>
<ng-container *ngIf="isAppReady">
    <app-header></app-header>
    <main class="flex-shrink-0">
        <div class="container main-container">
            <router-outlet></router-outlet>
        </div>
    </main>
    <app-footer *ngIf="isMobile || isMainFooterShown" class="mt-auto"></app-footer>
</ng-container>
