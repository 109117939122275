import { Component, OnDestroy, OnInit } from "@angular/core";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Subscription } from "rxjs";
import { UtilsService } from "../../../core/services/utils.service";

@Component({
    selector: "app-content-loader",
    templateUrl: "./content-loader.component.html",
    styleUrls: ["./content-loader.component.scss"],
})
export class ContentLoaderComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    icon = faCircleNotch;
    isContentLoaderShown = false;

    constructor(private utilsService: UtilsService) {
        this.subscriptions.push(
            this.utilsService.isContentLoaderShown$$.subscribe((isContentLoaderShown) => {
                this.isContentLoaderShown = isContentLoaderShown;
            })
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
