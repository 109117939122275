import { PaymentMethodModel } from './payment-method.model';
import { PaymentOptionModel } from './payment-option.model';
import { KYCModel } from './kyc.model';

export class ItemModel {
  name: string;
  quantity: number;
  price: number;

  constructor() {
    this.name = '';
    this.quantity = 0;
    this.price = 0;
  }
}

export class CurrencyModel {
  name: string;
  code: string;
  display_name: string;
  display_symbol: string;

  constructor() {
    this.name = '';
    this.code = '';
    this.display_name = '';
    this.display_symbol = '';
  }
}

export class CountryModel {
  name: string;
  a2_iso_code: string;

  constructor() {
    this.name = '';
    this.a2_iso_code = '';
  }
}

export class ChargeModel {
  charge_id: string;
  shop_name: string;
  shop_description: string;
  language: string;
  amount: number;
  currency: CurrencyModel;
  payment_methods: PaymentMethodModel[];
  payment_method: PaymentMethodModel;
  payment_options: PaymentOptionModel[];
  extra_steps: any;
  items?: ItemModel[];
  kyc?: KYCModel;
  countries: CountryModel[];
  callback_endpoint: string;
  redirect_url: string;
  access_token: string;
  created_at: number;
  expires_at: number;

  constructor() {
    this.charge_id = '';
    this.shop_name = '';
    this.shop_description = '';
    this.language = '';
    this.amount = 0;
    this.currency = new CurrencyModel();
    this.payment_methods = [];
    this.payment_method = new PaymentMethodModel();
    this.payment_options = [];
    this.countries = [];
    this.callback_endpoint = '';
    this.redirect_url = '';
    this.access_token = '';
    this.created_at = 0;
    this.expires_at = 0;
  }
}
