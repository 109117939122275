<div class="payment-currency-info-wrapper d-flex align-items-center">
    <div class="flex-shrink-0">
        <div class="payment-currency-info-icon-wrapper">
            <div class="payment-currency-info-icon">
                <img [src]="paymentOptionIcon" alt="payment-currency-info-icon" />
                <img [src]="selectedNetworkIcon" alt="Selected network" width="10" height="10" class="network">
            </div>
        </div>
    </div>
    <div class="flex-grow-1 payment-currency-info-title-wrapper">
        <div class="payment-currency-info-name">{{ selectedPaymentOption.name }}</div>
        <div class="payment-network-info-name" *ngIf="selectedPaymentNetwork">({{ selectedPaymentNetwork.short_name }})</div>
    </div>
    <div class="flex-end payment-currency-info">
        <span>{{ cryptoAmount }} {{ selectedPaymentOption.code }}</span>
        <span>{{ charge.currency.display_symbol }} {{ amount | currency:charge.currency.code:'':'1.2-2' }}</span>
    </div>
</div>
