<ng-container *ngIf="!isMobile">
    <div *ngIf="currentStep?.previousStep != null && !currentStep?.isLocked" class="previous-step-wrapper">
        <div class="previous-step-icon" (click)="onPreviousStepClick()"></div>
        <div class="previous-step-hover-text">{{ 'back_btn' | translate }}</div>
    </div>
</ng-container>
<ng-container *ngIf="isMobile">
    <div *ngIf="currentStep?.previousStep != null"
         class="previous-step-wrapper previous-step-mobile-wrapper {{ currentStep?.isLocked ? 'disabled' : '' }}">
        <div class="previous-step-icon" (click)="onPreviousStepClick()"></div>
    </div>
</ng-container>

