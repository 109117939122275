export class TransactionStatusModel {
    charge_id: string;
    order_number: string;
    status: string;
    qr_data?: string;
    diff_amount?: string;
    diff_crypto_amount?: string;

    constructor() {
        this.charge_id = "";
        this.order_number = "";
        this.status = "";
    }
}
