import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { PaymentMethodModel } from "../../../../core/models/payment-method.model";
import { paymentSteps, StepService } from "../../../../core/services/step.service";
import { PaymentService } from "../../../../core/services/payment.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-payment-method-item",
    templateUrl: "./payment-method-item.component.html",
    styleUrls: ["./payment-method-item.component.scss"],
})
export class PaymentMethodItemComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    @Input() paymentMethod: PaymentMethodModel = new PaymentMethodModel();
    paymentMethodIcon = "";

    constructor(private stepService: StepService, private paymentService: PaymentService) {}

    ngOnInit() {
        this.paymentMethodIcon = `assets/icons/payment-methods/${this.paymentMethod.type.toLowerCase()}-icon.svg`;
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    onClick() {
        this.paymentService.selectedPaymentMethod$$.next(this.paymentMethod);
        if (this.paymentMethod.payment_options.length === 0) {
            this.stepService.toStep(paymentSteps.PAYMENT_PROCESSOR);
        } else {
            this.stepService.toStep(paymentSteps.PAYMENT_OPTIONS);
        }
    }
}
