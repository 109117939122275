<div class="page-container">
    <h1 class="page-title mt-30">{{ 'pages.cookies.title' | translate }}</h1>
    <div class="page-date mb-30">{{ 'pages.cookies.date' | translate }}</div>
    <p [innerHTML]="'pages.cookies.description.0' | translate"></p>
    <ngb-accordion [closeOthers]="true">
        <ngb-panel id="cookies-acc-1" title="{{ 'pages.cookies.content.0.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHTML]="'pages.cookies.content.0.description.0' | translate"></p>
                <p [innerHTML]="'pages.cookies.content.0.description.1' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="cookies-acc-2" title="{{ 'pages.cookies.content.1.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHTML]="'pages.cookies.content.1.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="cookies-acc-3" title="{{ 'pages.cookies.content.2.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHTML]="'pages.cookies.content.2.description.0' | translate"></p>
                <p [innerHTML]="'pages.cookies.content.2.description.1' | translate"></p>
                <p [innerHTML]="'pages.cookies.content.2.description.2' | translate"></p>
                <p [innerHTML]="'pages.cookies.content.2.description.3' | translate"></p>
                <div class="table-responsive">
                    <table class="table table-sm">
                        <thead>
                        <tr>
                            <td>{{ 'pages.cookies.content.2.table_head.0' | translate }}</td>
                            <td>{{ 'pages.cookies.content.2.table_head.1' | translate }}</td>
                            <td>{{ 'pages.cookies.content.2.table_head.2' | translate }}</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ 'pages.cookies.content.2.table.0.name' | translate }}</td>
                            <td>{{ 'pages.cookies.content.2.table.0.duration' | translate }}</td>
                            <td>{{ 'pages.cookies.content.2.table.0.description' | translate }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="cookies-acc-4" title="{{ 'pages.cookies.content.3.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHTML]="'pages.cookies.content.3.description.0' | translate"></p>
                <p [innerHTML]="'pages.cookies.content.3.description.1' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="cookies-acc-5" title="{{ 'pages.cookies.content.4.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHTML]="'pages.cookies.content.4.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <p class="mt-30">{{ 'powered_by_clean' | translate }}</p>
</div>
