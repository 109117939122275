<div class="d-flex align-items-center payment-option-item" (click)="onClick()">
    <div class="flex-shrink-0">
        <div class="payment-option-item-icon-wrapper">
            <div class="payment-option-item-icon">
                <img [src]="icon" alt="payment-option-icon" />
            </div>
        </div>
    </div>
    <div class="flex-grow-1 payment-option-item-title-wrapper">
        <div class="payment-option-item-name">
            <ng-container>{{ paymentOption.name }}</ng-container>
            <ng-container *ngIf="paymentOption.section === 'CRYPTO_CURRENCY'">
                {{ paymentOption.code }}
            </ng-container>
        </div>
        <div *ngIf="this.paymentOption.payment_networks.length === 1" class="payment-option-item-network-name">{{ this.paymentOption.payment_networks[0].full_name }}</div>
        <div *ngIf="this.paymentOption.payment_networks.length > 1" class="payment-option-item-network-name">{{ 'payment_networks.multiple_networks' | translate }}</div>
    </div>
</div>
<div class="divider divider-light"></div>
