import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { QRCodeSVGModule } from "ngx-qrcode-svg";
import { ReactiveFormsModule } from "@angular/forms";
import { Select2Module } from "ng-select2-component";

import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ChargeComponent } from "./components/charge/charge.component";
import { ChargeDetailsComponent } from "./components/charge/charge-details/charge-details.component";
import { PaymentComponent } from "./components/payment/payment.component";
import { PaymentMethodsComponent } from "./components/payment/payment-methods/payment-methods.component";
import { PaymentMethodItemComponent } from "./components/payment/payment-methods/payment-method-item/payment-method-item.component";
import { PaymentOptionsComponent } from "./components/payment/payment-options/payment-options.component";
import { PaymentOptionItemComponent } from "./components/payment/payment-options/payment-option-item/payment-option-item.component";
import { PaymentProcessorComponent } from "./components/payment/payment-processor/payment-processor.component";
import { PaymentProgressBarComponent } from "./components/payment/payment-progress-bar/payment-progress-bar.component";
import { PaymentLoaderComponent } from "./components/payment/payment-loader/payment-loader.component";
import { SupportedPaymentMethodsComponent } from "./components/supported-payment-methods/supported-payment-methods.component";
import { PaymentStaticErrorComponent } from "./components/payment/payment-statuses/payment-static-error/payment-static-error.component";
import { PaymentStaticSuccessComponent } from "./components/payment/payment-statuses/payment-static-success/payment-static-success.component";
import { PaymentErrorComponent } from "./components/payment/payment-statuses/payment-error/payment-error.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { CookiesComponent } from "./pages/cookies/cookies.component";
import { PaymentCurrencyInfoComponent } from "./components/payment/payment-currency-info/payment-currency-info.component";
import { PaymentCurrencyAlertComponent } from "./components/payment/payment-currency-alert/payment-currency-alert.component";
import { PaymentNavComponent } from "./components/payment/payment-nav/payment-nav.component";
import { PaymentClaimRefundComponent } from "./components/payment/payment-claim-refund/payment-claim-refund.component";
import { PaymentKycComponent } from "./components/payment/payment-kyc/payment-kyc.component";
import { PoweredByBitfinexComponent } from "./components/powered-by-bitfinex/powered-by-bitfinex.component";

import { environment } from "../environments/environment";
import { registerLocaleData } from "@angular/common";
import { LocaleProvider } from "./core/providers/locale.provider";

import locale_en from "@angular/common/locales/en";
import locale_sl from "@angular/common/locales/sl";
import locale_ro from "@angular/common/locales/ro";
import locale_fr from "@angular/common/locales/fr";
import locale_es from "@angular/common/locales/es";
import locale_it from "@angular/common/locales/it";
import locale_de from "@angular/common/locales/de";
import { PaymentNetworksComponent } from './components/payment/payment-networks/payment-networks.component';
import { PaymentNetworkItemComponent } from './components/payment/payment-networks/payment-network-item/payment-network-item.component';

registerLocaleData(locale_en, "en");
registerLocaleData(locale_sl, "sl");
registerLocaleData(locale_ro, "ro");
registerLocaleData(locale_fr, "fr");
registerLocaleData(locale_es, "es");
registerLocaleData(locale_it, "it");
registerLocaleData(locale_de, "de");

@NgModule({
    declarations: [
        AppComponent,
        ChargeComponent,
        ChargeDetailsComponent,
        PaymentComponent,
        PaymentMethodsComponent,
        PaymentMethodItemComponent,
        PaymentOptionsComponent,
        PaymentOptionItemComponent,
        PaymentProcessorComponent,
        SupportedPaymentMethodsComponent,
        PaymentStaticErrorComponent,
        PaymentStaticSuccessComponent,
        PaymentErrorComponent,
        TermsComponent,
        CookiesComponent,
        PaymentProgressBarComponent,
        PaymentLoaderComponent,
        PaymentCurrencyInfoComponent,
        PaymentCurrencyAlertComponent,
        PaymentNavComponent,
        PaymentClaimRefundComponent,
        PaymentKycComponent,
        PoweredByBitfinexComponent,
        PaymentNetworksComponent,
        PaymentNetworkItemComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        NgbModule,
        FontAwesomeModule,
        QRCodeSVGModule,
        // custom modules
        CoreModule,
        SharedModule,
        ReactiveFormsModule,
        Select2Module,
    ],
    providers: [LocaleProvider],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
