import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ChargeService } from './charge.service';
import { catchError, map } from 'rxjs/operators';
import { PaymentMethodModel } from '../models/payment-method.model';
import { TenantService } from './tenant.service';
import { UtilsService } from './utils.service';
import { PaymentOptionModel } from '../models/payment-option.model';
import { ChargeModel } from '../models/charge.model';
import { PaymentModel } from '../models/payment.model';
import { TransactionStatusModel } from '../models/transaction-status.models';
import { ClaimRefundModel } from '../models/claim-refund.model';
import { LocaleService } from './locale.service';
import { KYCModel } from '../models/kyc.model';
import { v4 as uuidv4 } from 'uuid';
import { PaymentNetworkModel } from '../models/payment-network.model';

export enum paymentStatuses {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  OVERPAID = 'OVERPAID',
  UNDERPAID = 'UNDERPAID',
  EXPIRED = 'EXPIRED',
  TERMINATED = 'TERMINATED',
  FAILED = 'FAILED'
}

export enum paymentNavigationItems {
  SCAN = 'SCAN',
  MANUAL = 'MANUAL'
}

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  payment$$: BehaviorSubject<PaymentModel> = new BehaviorSubject<PaymentModel>(new PaymentModel());
  transactionStatus$$: BehaviorSubject<TransactionStatusModel> = new BehaviorSubject<TransactionStatusModel>(
    new TransactionStatusModel()
  );
  selectedPaymentMethod$$: BehaviorSubject<PaymentMethodModel> = new BehaviorSubject<PaymentMethodModel>(
    new PaymentMethodModel()
  );
  selectedPaymentOption$$: BehaviorSubject<PaymentOptionModel> = new BehaviorSubject<PaymentOptionModel>(
    new PaymentOptionModel()
  );
  selectedPaymentNetwork$$: BehaviorSubject<PaymentNetworkModel> = new BehaviorSubject<PaymentNetworkModel>(
    new PaymentNetworkModel()
  );
  claimRefundForm$$: BehaviorSubject<ClaimRefundModel> = new BehaviorSubject<ClaimRefundModel>(new ClaimRefundModel());
  deepLinks$$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  paymentStatus$$: BehaviorSubject<string> = new BehaviorSubject<string>(paymentStatuses.IN_PROGRESS);
  paymentFullTime$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  paymentTimeLeft$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  paymentProgressTime$$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  paymentProgressPercentage$$: BehaviorSubject<number> = new BehaviorSubject<number>(100);
  completedPaymentStatuses: string[] = [
    paymentStatuses.SUCCESS,
    paymentStatuses.EXPIRED,
    paymentStatuses.OVERPAID,
    paymentStatuses.UNDERPAID
  ];
  transactionInterval: any;
  kyc$$: BehaviorSubject<KYCModel> = new BehaviorSubject<KYCModel>(new KYCModel());

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private chargeService: ChargeService,
    private tenantService: TenantService,
    private utilsService: UtilsService,
    private localeService: LocaleService
  ) {}

  generatePayment(
    paymentMethod: PaymentMethodModel,
    paymentOption: PaymentOptionModel,
    paymentNetwork: PaymentNetworkModel
  ) {
    let paymentBody: any = {
      payment_method: paymentMethod.type,
      amount: this.chargeService.charge$$.value.amount,
      payment_details: this.getPaymentDetails(),
      charge_id: this.chargeService.charge$$.value.charge_id
    };

    if (paymentOption && paymentOption.code) {
      paymentBody = {
        ...paymentBody,
        payment_option: paymentOption.code
      };
    }

    if (paymentNetwork && paymentNetwork.network_type) {
      paymentBody = {
        ...paymentBody,
        payment_network: paymentNetwork.network_type
      };
    }

    if (paymentOption && paymentOption.code && paymentOption.kyc_required) {
      paymentBody = {
        ...paymentBody,
        kyc: this.kyc$$.value
      };
    }

    return this.http
      .post(`${this.tenantService.API_ENDPOINT}/payments/generate/`, paymentBody, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.chargeService.charge$$.value.access_token}`
        })
      })
      .pipe(
        map((data: any) => {
          // set payment
          // this.payment$$.next(data);

          // set deep links
          if (data.deep_links) {
            this.deepLinks$$.next(data.deep_links);
          }

          return data;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  checkTransactionStatus(transactionId = '') {
    transactionId = transactionId ?? this.payment$$.value.transaction_id;

    return this.http
      .get(`${this.tenantService.API_ENDPOINT}/v2/transactions/${transactionId}/status/`, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.chargeService.charge$$.value.access_token}`
        })
      })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(() => {
          return throwError(() => 'Check transaction status failed!');
        })
      );
  }

  terminatePayment() {
    return this.http
      .post(
        `${this.tenantService.API_ENDPOINT}/payments/terminate/`,
        {
          payment_code: this.payment$$.value.payment_code === '' ? uuidv4() : this.payment$$.value.payment_code,
          charge_id: this.chargeService.charge$$.value.charge_id
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.chargeService.charge$$.value.access_token}`
          })
        }
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(() => {
          return throwError(() => 'Payment terminate failed!');
        })
      );
  }

  claimRefund(transactionId: string, formData: object) {
    return this.http
      .post(
        `${this.tenantService.API_ENDPOINT}/payments/claim-refund/`,
        {
          transaction_id: transactionId,
          ...formData
        },
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept-Language': this.localeService.currentLocale,
            Authorization: `Bearer ${this.chargeService.charge$$.value.access_token}`
          })
        }
      )
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError(() => {
          return throwError(() => 'Claim refund failed!');
        })
      );
  }

  getPaymentDetails() {
    const charge: ChargeModel = this.chargeService.charge$$.value;
    let paymentDetails = {};

    // if ("order_number" in charge) {
    //     paymentDetails = {
    //         ...paymentDetails,
    //         order_number: charge.order_number,
    //     };
    // }
    //
    // if ("discount" in charge) {
    //     paymentDetails = {
    //         ...paymentDetails,
    //         discount: charge.discount,
    //     };
    // }

    if ('items' in charge) {
      paymentDetails = {
        ...paymentDetails,
        items: charge.items
      };
    }

    return paymentDetails;
  }

  getQRCodeIcon() {
    const excludeSections = ['CRYPTO_CURRENCY'];
    const selectedPaymentOptionSection = this.selectedPaymentOption$$.value.section;
    if (excludeSections.includes(selectedPaymentOptionSection)) {
      return '';
    }
    if (selectedPaymentOptionSection === 'WALLET') {
      return `assets/icons/payment-options/${this.selectedPaymentOption$$.value.code.toLowerCase()}-wallet-icon.svg`;
    }
    return `assets/icons/payment-methods/${this.selectedPaymentMethod$$.value.type.toLowerCase()}-icon.svg`;
  }

  resetPayment() {
    this.payment$$.next(new PaymentModel());
    this.paymentStatus$$.next(paymentStatuses.IN_PROGRESS);
    this.paymentFullTime$$.next(0);
    this.paymentTimeLeft$$.next(0);
    this.paymentProgressTime$$.next('');
    this.paymentProgressPercentage$$.next(100);
    this.deepLinks$$.next({});
    this.terminateTransactionStatusInterval();
  }

  startTransactionStatusInterval(includeProgressTime = false, transactionId = '') {
    this.transactionInterval = setInterval(() => {
      if (this.paymentTimeLeft$$.value > 0 && this.paymentStatus$$.value === paymentStatuses.IN_PROGRESS) {
        this.paymentTimeLeft$$.next(this.paymentTimeLeft$$.value - 1);
        if (includeProgressTime) {
          this.paymentProgressTime$$.next(this.utilsService.parseTime(this.paymentTimeLeft$$.value));
          this.paymentProgressPercentage$$.next(this.calculateProgressPercent());
        }
        this.checkTransactionStatus(transactionId).subscribe({
          next: (data: TransactionStatusModel) => {
            this.transactionStatus$$.next(data);
            if (data.status !== paymentStatuses.IN_PROGRESS) {
              if (this.completedPaymentStatuses.includes(data.status)) {
                this.paymentStatus$$.next(data.status);
              } else {
                this.paymentStatus$$.next(paymentStatuses.FAILED);
              }
              this.terminateTransactionStatusInterval();
            }
          },
          error: () => {
            this.paymentStatus$$.next(paymentStatuses.FAILED);
            this.terminateTransactionStatusInterval();
          }
        });
      } else {
        this.paymentStatus$$.next(paymentStatuses.EXPIRED);
        this.terminateTransactionStatusInterval();
      }
    }, 1000);
  }

  terminateTransactionStatusInterval() {
    clearInterval(this.transactionInterval);
  }

  calculateProgressPercent() {
    return (this.paymentTimeLeft$$.value * 100) / this.paymentFullTime$$.value;
  }

  checkKYC(): boolean {
    const kyc = this.kyc$$.value;
    let kycValid = false;
    Object.entries(kyc).forEach(([_, attributeValue]) => {
      kycValid = attributeValue !== '';
    });
    return kycValid;
  }
}
