import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '../../../core/services/payment.service';
import { KYCModel } from '../../../core/models/kyc.model';
import { paymentSteps, StepService } from '../../../core/services/step.service';
import { ChargeService } from '../../../core/services/charge.service';
import { CountryModel } from '../../../core/models/charge.model';

@Component({
  selector: 'app-payment-kyc',
  templateUrl: './payment-kyc.component.html',
  styleUrls: ['./payment-kyc.component.scss']
})
export class PaymentKycComponent implements OnInit {
  kyc: KYCModel;
  KYCForm: FormGroup;
  nationalitiesList: any[] = [];
  countriesList: any[] = [];

  constructor(
    private chargeService: ChargeService,
    private paymentService: PaymentService,
    private stepService: StepService,
    private fb: FormBuilder
  ) {
    // get kyc data
    this.kyc = this.paymentService.kyc$$.value;
    // init form
    this.KYCForm = this.fb.group({
      email: [this.kyc.email, [Validators.required, Validators.email]],
      first_name: [this.kyc.first_name, [Validators.required]],
      last_name: [this.kyc.last_name, [Validators.required]],
      nationality: [this.kyc.nationality, [Validators.required]],
      country: [this.kyc.country, [Validators.required]],
      city: [this.kyc.city, [Validators.required]],
      zip_code: [this.kyc.zip_code, [Validators.required]],
      address: [this.kyc.address, [Validators.required]],
      building_number: [this.kyc.building_number],
      terms: [this.kyc.terms, [Validators.requiredTrue]]
    });
    // set nationalities
    this.chargeService.charge$$.value.countries.map((country: CountryModel): void => {
      this.nationalitiesList.push({
        value: country.a2_iso_code,
        label: country.name
      });
    });
    // set countries
    this.chargeService.charge$$.value.countries.map((country: CountryModel): void => {
      this.countriesList.push({
        value: country.a2_iso_code,
        label: country.name
      });
    });
  }

  ngOnInit(): void {}

  get f() {
    return this.KYCForm.controls;
  }

  resetField(field: string) {
    this.KYCForm.patchValue({
      [field]: ''
    });
  }

  onKYCSubmit(): void {
    if (this.KYCForm.valid) {
      this.paymentService.kyc$$.next({
        email: this.f['email'].value,
        first_name: this.f['first_name'].value,
        last_name: this.f['last_name'].value,
        nationality: this.f['nationality'].value,
        country: this.f['country'].value,
        city: this.f['city'].value,
        zip_code: this.f['zip_code'].value,
        address: this.f['address'].value,
        building_number: this.f['building_number'].value,
        terms: this.f['terms'].value
      });
      this.stepService.toStep(paymentSteps.PAYMENT_PROCESSOR);
    } else {
      this.validateAllFormFields();
    }
  }

  isFieldValid(field: string): boolean {
    // @ts-ignore
    return !this.KYCForm.get(field).valid && this.KYCForm.get(field).touched;
  }

  displayErrorFieldCSS(field: string): { 'has-error': boolean } {
    return {
      'has-error': this.isFieldValid(field)
    };
  }

  validateAllFormFields(): void {
    Object.keys(this.KYCForm.controls).forEach((field: string): void => {
      const control: AbstractControl | null = this.KYCForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }

  onNationalitySelect(event: any): void {
    this.f['nationality'].setValue(event.value);
    this.onInputChange(event.value, 'nationality');
  }

  onCountrySelect(event: any): void {
    this.f['country'].setValue(event.value);
    this.onInputChange(event.value, 'country');
  }

  onInputChange(newValue: string, field: string): void {
    let kycData: any = this.paymentService.kyc$$.value;
    kycData[field] = newValue;
    this.paymentService.kyc$$.next(kycData);
  }
}
