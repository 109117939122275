<header class="fixed-top">
    <div class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <div class="d-flex align-items-center text-white text-decoration-none">
                <img [src]="sanitizing.bypassSecurityTrustUrl(logo)" class="logo" alt="logo" />
            </div>
            <div class="me-auto"></div>
            <div class="text-end">
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="languageSwitcher"
                            ngbDropdownToggle>{{ localeService.currentLocale | uppercase }}</button>
                    <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="languageSwitcher">
                        <button ngbDropdownItem *ngFor="let language of languages | keyvalue"
                                (click)="onLangChange(language.key)">{{ language.value }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>
