import { Component, OnDestroy, OnInit } from "@angular/core";
import { AnimationOptions } from "ngx-lottie";
import { Subscription } from "rxjs";
import { UtilsService } from "../../../core/services/utils.service";

@Component({
    selector: "app-box-loader",
    templateUrl: "./box-loader.component.html",
    styleUrls: ["./box-loader.component.scss"],
})
export class BoxLoaderComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    isBoxLoaderShown = false;
    loaderOptions: AnimationOptions = {
        path: "/assets/loader.json",
    };

    constructor(private utilsService: UtilsService) {
        this.subscriptions.push(
            this.utilsService.isBoxLoaderShown$$.subscribe((isBoxLoaderShown) => {
                this.isBoxLoaderShown = isBoxLoaderShown;
            })
        );
    }

    ngOnInit(): void {}

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
