export class PaymentNetworkModel {
  network_type: string;
  short_name: string;
  full_name: string;
  powered_by: string;
  order_by: number;

  constructor() {
    this.network_type = '';
    this.short_name = '';
    this.full_name = '';
    this.powered_by = '';
    this.order_by = 0;
  }
}
