<div class="d-flex align-items-center payment-method-item" (click)="onClick()">
    <div class="flex-shrink-0">
        <div class="payment-method-icon-wrapper">
            <div class="payment-method-icon">
                <img *ngIf="paymentMethodIcon" [src]="paymentMethodIcon" alt="payment-method-icon" />
            </div>
        </div>
    </div>
    <div class="flex-grow-1">
        <div class="payment-method-name">
            <ng-container *ngIf="paymentMethod.type === 'CARD'">{{ 'payment_method_card' | translate }}</ng-container>
            <ng-container
                *ngIf="paymentMethod.type === 'GOCRYPTO'">{{ 'payment_method_gocrypto' | translate }}</ng-container>
            <ng-container
                *ngIf="!['CARD', 'GOCRYPTO'].includes(paymentMethod.type)">{{ paymentMethod.name }}</ng-container>
        </div>
    </div>
</div>
<div class="divider divider-light"></div>
