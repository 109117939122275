<div class="d-flex align-items-center payment-network-item" (click)="onClick()">
    <div class="flex-shrink-0">
        <div class="payment-network-item-icon-wrapper">
            <div class="payment-network-item-icon">
                <img [src]="icon" alt="payment-network-icon" />
            </div>
        </div>
    </div>
    <div class="flex-grow-1 payment-network-item-title-wrapper">
        <div class="payment-network-item-name">{{ paymentNetwork.full_name }}</div>
    </div>
</div>
<div class="divider divider-light"></div>
