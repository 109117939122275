import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from '../../../core/services/helper.service';
import { PaymentService, paymentStatuses } from '../../../core/services/payment.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ChargeService } from '../../../core/services/charge.service';
import { ChargeModel } from '../../../core/models/charge.model';
import { ErrorService } from '../../../core/services/error.service';
import { UtilsService } from '../../../core/services/utils.service';
import { ClaimRefundModel } from '../../../core/models/claim-refund.model';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { KYCModel } from '../../../core/models/kyc.model';
import { PaymentOptionModel } from '../../../core/models/payment-option.model';
import { PaymentNetworkModel } from '../../../core/models/payment-network.model';
import { paymentSteps, StepService } from '../../../core/services/step.service';

@Component({
  selector: 'app-payment-claim-refund',
  templateUrl: './payment-claim-refund.component.html',
  styleUrls: ['./payment-claim-refund.component.scss']
})
export class PaymentClaimRefundComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  claimRefund: ClaimRefundModel;
  charge: ChargeModel;
  kyc: KYCModel;
  claimRefundForm: FormGroup;
  selectedPaymentOption: PaymentOptionModel;
  selectedPaymentNetwork: PaymentNetworkModel;

  paymentStatuses = paymentStatuses;

  isClaimRefundSuccess = false;
  successStatusIcon = '';
  networkIcon = '';
  paymentOptionIcon = '';

  isRedirectShown = false;
  callbackEndpoint = '';

  currentLang: string;
  supportEmail: string;

  constructor(
    private chargeService: ChargeService,
    private paymentService: PaymentService,
    private helperService: HelperService,
    private fb: FormBuilder,
    private errorService: ErrorService,
    private utilsService: UtilsService,
    private translate: TranslateService,
    public sanitizing: DomSanitizer,
    private stepService: StepService
  ) {
    this.selectedPaymentOption = this.paymentService.selectedPaymentOption$$.value;
    this.selectedPaymentNetwork = this.paymentService.selectedPaymentNetwork$$.value;
    this.claimRefund = this.paymentService.claimRefundForm$$.value;
    this.charge = this.chargeService.charge$$.value;
    this.kyc = this.paymentService.kyc$$.value;
    this.successStatusIcon = this.helperService.paymentStatusesIcons[paymentStatuses.SUCCESS];
    this.networkIcon = `assets/icons/payment-networks/network-${this.selectedPaymentNetwork.network_type.toLowerCase()}-primary-icon.svg`;
    this.paymentOptionIcon = `assets/icons/payment-options/${this.selectedPaymentOption.code.toLowerCase()}-${this.selectedPaymentOption.section.toLowerCase()}-icon.svg`;
    // init form
    this.claimRefundForm = this.fb.group({
      email: [this.kyc.email ?? '', [Validators.required, Validators.email]],
      address: ['', [Validators.required]]
    });
    // set default language
    this.currentLang = environment.defaultLanguage;
    // get support email
    this.supportEmail = this.helperService.getSupportEmail(this.currentLang);
    // subscribe to language change
    this.subscriptions.push(
      translate.onLangChange.subscribe((lang) => {
        // set current language
        this.currentLang = lang.lang;
        // get support email
        this.supportEmail = this.helperService.getSupportEmail(this.currentLang);
      })
    );
  }

  ngOnInit() {
    this.stepService.currentStep$$.next({ ...this.stepService.currentStep$$.value, previousStep: 'PAYMENT_PROCESSOR' });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onPaymentProcessorForm() {
    this.stepService.toStep(paymentSteps.PAYMENT_PROCESSOR);
  }

  get f() {
    return this.claimRefundForm.controls;
  }

  onClaimRefundSubmit() {
    if (this.claimRefundForm.valid) {
      const formData = {
        email: this.f['email'].value,
        address: this.f['address'].value
      };
      this.paymentService.claimRefund(this.claimRefund.transactionId, formData).subscribe({
        error: (e) => {
          console.log(e);
          this.errorService.setAlert('error', 'Claim refund failed!');
        },
        complete: () => {
          this.isClaimRefundSuccess = true;
          // skip unload confirm
          this.utilsService.skipUnloadConfirm$$.next(true);
          // set timer to delete charge and redirect user to callback endpoint
          setTimeout(() => {
            this.callbackEndpoint = this.utilsService.modifyUrl(
              this.charge.callback_endpoint,
              `payment_method=${this.claimRefund.paymentMethod.type}&payment_status=${this.paymentStatuses.SUCCESS}&transaction_id=${this.claimRefund.transactionId}`
            );
            this.chargeService.deleteCharge().subscribe({
              error: () => (window.location.href = this.callbackEndpoint),
              complete: () => (window.location.href = this.callbackEndpoint)
            });
          }, 2000);
          // set timer to show redirect button if user is not redirected
          setTimeout(() => {
            this.isRedirectShown = true;
          }, 4000);
        }
      });
    } else {
      this.validateAllFormFields();
    }
  }

  resetField(field: string) {
    this.claimRefundForm.patchValue({
      [field]: ''
    });
  }

  isFieldValid(field: string) {
    // @ts-ignore
    return !this.claimRefundForm.get(field).valid && this.claimRefundForm.get(field).touched;
  }

  displayErrorFieldCSS(field: string) {
    return {
      'has-error': this.isFieldValid(field)
    };
  }

  validateAllFormFields() {
    Object.keys(this.claimRefundForm.controls).forEach((field) => {
      const control = this.claimRefundForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
  }
}
