<div class="payment-networks-title">{{ 'payment_networks.title' | translate }}</div>
<div class="divider divider-light"></div>
<app-payment-network-item *ngFor="let paymentNetwork of paymentNetworks"
                          [paymentNetwork]="paymentNetwork"></app-payment-network-item>
<div class="payment-description-wrapper payment-networks-description-wrapper">
    <p [innerHTML]="'payment_networks.' + selectedPaymentOption.code + '.description' | translate"></p>
    <a href="{{ 'payment_networks.' + selectedPaymentOption.code + '.how_to_link' | translate }}"
       class="btn-link-primary"
       target="_blank">{{ 'payment_networks.' + selectedPaymentOption.code + '.how_to_pay' | translate }}</a>
</div>
