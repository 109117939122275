<app-content-loader></app-content-loader>
<app-alert></app-alert>
<ng-container [ngSwitch]="currentStep?.step">
    <app-payment-methods *ngSwitchCase="paymentSteps.PAYMENT_METHOD_SELECT"></app-payment-methods>
    <app-payment-options *ngSwitchCase="paymentSteps.PAYMENT_OPTIONS"></app-payment-options>
    <app-payment-networks *ngSwitchCase="paymentSteps.PAYMENT_NETWORKS"></app-payment-networks>
    <app-payment-kyc *ngSwitchCase="paymentSteps.PAYMENT_KYC"></app-payment-kyc>
    <app-payment-processor *ngSwitchCase="paymentSteps.PAYMENT_PROCESSOR"></app-payment-processor>
    <app-payment-claim-refund *ngSwitchCase="paymentSteps.PAYMENT_CLAIM_REFUND"></app-payment-claim-refund>
    <app-payment-static-success *ngSwitchCase="paymentSteps.PAYMENT_STATIC_SUCCESS"></app-payment-static-success>
    <app-payment-static-error *ngSwitchCase="paymentSteps.PAYMENT_STATIC_ERROR"></app-payment-static-error>
    <app-payment-error *ngSwitchCase="paymentSteps.PAYMENT_ERROR"></app-payment-error>
</ng-container>
