import { Injectable } from "@angular/core";
import { BehaviorSubject, throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { TenantService } from "./tenant.service";
import { ChargeModel } from "../models/charge.model";
import { PaymentMethodModel } from "../models/payment-method.model";

@Injectable({
    providedIn: "root",
})
export class ChargeService {
    charge$$: BehaviorSubject<ChargeModel> = new BehaviorSubject<ChargeModel>(new ChargeModel());
    paymentMethods$$: BehaviorSubject<PaymentMethodModel[]> = new BehaviorSubject<PaymentMethodModel[]>([]);

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
        private tenantService: TenantService
    ) {}

    getCharge(chargeId: string) {
        return this.http
            .get(`${this.tenantService.API_ENDPOINT}/payments/charge/${chargeId}/`, {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                }),
            })
            .pipe(
                map((data: ChargeModel | any) => {
                    this.charge$$.next(data);
                    this.paymentMethods$$.next(data.payment_methods);
                    return data;
                }),
                catchError(() => {
                    return throwError(() => "Something went wrong by fetching charge details");
                })
            );
    }

    deleteCharge() {
        const chargeId = this.charge$$.value.charge_id;
        return this.http
            .post(
                `${this.tenantService.API_ENDPOINT}/payments/charge/delete/`,
                {
                    charge_id: chargeId,
                },
                {
                    headers: new HttpHeaders({
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.charge$$.value.access_token}`,
                    }),
                }
            )
            .pipe(
                map((data: any) => {
                    return data;
                }),
                catchError(() => {
                    return throwError(() => "Something went wrong by deleting charge");
                })
            );
    }
}
