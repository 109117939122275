<div *ngIf="!isMobile" class="row">
    <div class="col-lg-6 container-col charge-details-col-wrapper">
        <div class="col-wrapper charge-details-wrapper">
            <app-charge-info></app-charge-info>
        </div>
        <app-footer></app-footer>
    </div>
    <div class="col-lg-6 container-col charge-col-wrapper">
        <app-previous-step></app-previous-step>
        <div class="col-wrapper charge-wrapper">
            <app-payment [currentStep]="currentStep"></app-payment>
        </div>
    </div>
</div>
<div *ngIf="isMobile" class="row row-mobile">
    <div class="col-wrapper charge-col-wrapper-mobile">
        <div class="charge-details-container">
            <app-charge-info></app-charge-info>
        </div>
        <div class="charge-container">
            <app-previous-step></app-previous-step>
            <app-payment [currentStep]="currentStep"></app-payment>
        </div>
    </div>
</div>
