import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UtilsService } from '../../core/services/utils.service';

@Component({
  selector: 'app-cookies',
  templateUrl: './cookies.component.html'
})
export class CookiesComponent implements OnInit, OnDestroy {
  constructor(private renderer: Renderer2, private utilsService: UtilsService) {
    this.renderer.removeClass(document.body, 'gradient');
    this.renderer.addClass(document.body, 'default');
    this.renderer.addClass(document.body, 'bg-white');
    this.utilsService.isMainFooterShown$$.next(true);
    this.utilsService.hideLoader();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.utilsService.isMainFooterShown$$.next(false);
    this.renderer.removeClass(document.body, 'default');
    this.renderer.removeClass(document.body, 'bg-white');
    this.renderer.addClass(document.body, 'gradient');
  }
}
