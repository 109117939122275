import { Component, OnInit } from "@angular/core";
import { paymentSteps, StepService } from "../../../../core/services/step.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperService } from "../../../../core/services/helper.service";

@Component({
    selector: "app-payment-error",
    templateUrl: "./payment-error.component.html",
    styleUrls: ["./payment-error.component.scss"],
})
export class PaymentErrorComponent implements OnInit {
    constructor(
        private stepService: StepService,
        public helperService: HelperService,
        public sanitizing: DomSanitizer
    ) {}

    ngOnInit() {}

    onRetry() {
        // this.stepService.toStep(paymentSteps.WALLET_SELECT);
    }
}
