import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { PaymentService, paymentStatuses } from "../../../core/services/payment.service";
import { ChargeService } from "../../../core/services/charge.service";
import { UtilsService } from "../../../core/services/utils.service";
import { ChargeModel } from "../../../core/models/charge.model";

@Component({
    selector: "app-charge-info",
    templateUrl: "./charge-details.component.html",
    styleUrls: ["./charge-details.component.scss"],
})
export class ChargeDetailsComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    charge: ChargeModel = new ChargeModel();
    currencyCode = "";
    currencyDisplaySymbol = "";
    isMobile = false;
    isTerminatePaymentShown = false;
    isItemsDetailsShown = false;

    constructor(
        private translate: TranslateService,
        private paymentService: PaymentService,
        private chargeService: ChargeService,
        private utilsService: UtilsService
    ) {
        // set charge details data
        this.subscriptions.push(
            this.chargeService.charge$$.subscribe((charge: ChargeModel) => {
                if (Object.keys(charge).length > 0) {
                    this.charge = charge;
                    this.currencyCode = this.charge.currency.code;
                    this.currencyDisplaySymbol = this.charge.currency.display_symbol;
                }
            })
        );

        // subscribe to is terminate payment shown
        this.subscriptions.push(
            this.utilsService.isTerminatePaymentShown$$.subscribe((isTerminatePaymentShown) => {
                this.isTerminatePaymentShown = isTerminatePaymentShown;
            })
        );

        // subscribe to is mobile
        this.subscriptions.push(
            this.utilsService.isMobile$$.subscribe((isMobile) => {
                this.isMobile = isMobile;
                this.isItemsDetailsShown = !isMobile;
            })
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    onTerminatePayment() {
        // show loader
        this.utilsService.showBoxLoader();

        // set callback endpoint
        let callbackEndpoint = this.utilsService.modifyUrl(
            this.chargeService.charge$$.value.callback_endpoint,
            `payment_status=${paymentStatuses.TERMINATED}`
        );

        // add payment method to callback endpoint if exists
        const selectedPaymentMethod = this.paymentService.selectedPaymentMethod$$.value;
        if (selectedPaymentMethod.type !== "") {
            const paymentMethod = this.paymentService.selectedPaymentMethod$$.value.type;
            callbackEndpoint = this.utilsService.modifyUrl(callbackEndpoint, `payment_method=${paymentMethod}`);
        }

        // skip unload confirm
        this.utilsService.skipUnloadConfirm$$.next(true);

        if (this.paymentService.payment$$.value !== null) {
            this.paymentService.terminatePayment().subscribe({
                error: () => (window.location.href = callbackEndpoint),
                complete: () => (window.location.href = callbackEndpoint),
            });
        } else {
            // delete charge
            this.chargeService.deleteCharge().subscribe({
                error: () => (window.location.href = callbackEndpoint),
                complete: () => (window.location.href = callbackEndpoint),
            });
        }
    }

    onItemsListToggle() {
        this.isItemsDetailsShown = !this.isItemsDetailsShown;
    }
}
