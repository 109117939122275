import { PaymentOptionModel } from "./payment-option.model";

export class PaymentMethodModel {
    type: string;
    name: string;
    group: string;
    payment_options: PaymentOptionModel[];

    constructor() {
        this.type = "";
        this.name = "";
        this.group = "";
        this.payment_options = [];
    }
}
