<div class="payment-kyc-wrapper">
    <div class="payment-title">{{ 'kyc.title' | translate }}</div>
    <div class="payment-wrapper">
        <div class="payment-kyc-description">{{ 'kyc.description' | translate }}</div>
        <form [formGroup]="KYCForm" (submit)="onKYCSubmit()">
            <div class="form-group" [ngClass]="displayErrorFieldCSS('email')">
                <label>{{ 'kyc.email_label' | translate }}</label>
                <div class="input__wrap">
                    <input (ngModelChange)="onInputChange($event, 'email')" type="text" name="email" formControlName="email" class="form-control"
                           placeholder="{{ 'kyc.email_placeholder' | translate }}" />
                    <button *ngIf="KYCForm.controls['email'].value" type="button" aria-label="Reset Email" (click)="resetField('email')" class="input__reset">&#x2715;</button>
                </div>
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['email']
                            }"></ng-container>
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'email',
                                message: 'errors.email_invalid_format' | translate,
                                control: KYCForm.controls['email']
                            }"></ng-container>
            </div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('first_name')">
                <label>{{ 'kyc.first_name_label' | translate }}</label>
                <input (ngModelChange)="onInputChange($event, 'first_name')" type="text" name="first_name" formControlName="first_name" class="form-control"
                       placeholder="{{ 'kyc.first_name_placeholder' | translate }}" />
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['first_name']
                            }"></ng-container>
            </div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('last_name')">
                <label>{{ 'kyc.last_name_label' | translate }}</label>
                <input (ngModelChange)="onInputChange($event, 'last_name')" type="text" name="last_name" formControlName="last_name" class="form-control"
                       placeholder="{{ 'kyc.last_name_placeholder' | translate }}" />
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['last_name']
                            }"></ng-container>
            </div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('nationality')">
                <label>{{ 'kyc.nationality_label' | translate }}</label>
                <select2 [data]="nationalitiesList" [value]="kyc.nationality" (update)="onNationalitySelect($event)"
                         placeholder="{{ 'kyc.nationality_placeholder' | translate }}"></select2>
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['nationality']
                            }"></ng-container>
            </div>
            <div class="form-group-label">{{ 'kyc.address_separator_label' | translate }}</div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('country')">
                <label>{{ 'kyc.country_label' | translate }}</label>
                <select2 [data]="countriesList" [value]="kyc.country" (update)="onCountrySelect($event)"
                         placeholder="{{ 'kyc.country_placeholder' | translate }}"></select2>
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['country']
                            }"></ng-container>
            </div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('city')">
                <label>{{ 'kyc.city_label' | translate }}</label>
                <input (ngModelChange)="onInputChange($event, 'city')" type="text" name="city" formControlName="city" class="form-control"
                       placeholder="{{ 'kyc.city_placeholder' | translate }}" />
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['city']
                            }"></ng-container>
            </div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('zip_code')">
                <label>{{ 'kyc.zip_code_label' | translate }}</label>
                <input (ngModelChange)="onInputChange($event, 'zip_code')" type="text" name="zip_code" formControlName="zip_code" class="form-control"
                       placeholder="{{ 'kyc.zip_code_placeholder' | translate }}" />
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['zip_code']
                            }"></ng-container>
            </div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('address')">
                <label>{{ 'kyc.address_label' | translate }}</label>
                <input (ngModelChange)="onInputChange($event, 'address')" type="text" name="address" formControlName="address" class="form-control"
                       placeholder="{{ 'kyc.address_placeholder' | translate }}" />
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['address']
                            }"></ng-container>
            </div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('building_number')">
                <label>{{ 'kyc.building_number_label' | translate }}</label>
                <input (ngModelChange)="onInputChange($event, 'building_number')" type="text" name="building_number" formControlName="building_number" class="form-control"
                       placeholder="{{ 'kyc.building_number_placeholder' | translate }}" />
                <ng-container
                    [ngTemplateOutlet]="formError"
                    [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: KYCForm.controls['building_number']
                            }"></ng-container>
            </div>
            <div class="form-group-label">{{ 'kyc.terms_separator_label' | translate }}</div>
            <div class="form-group" [ngClass]="displayErrorFieldCSS('terms')">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="terms" formControlName="terms" value="1" id="terms" />
                    <label class="form-check-label" for="terms">{{ 'kyc.terms_label' | translate }}&nbsp;<a href="https://www.bitfinex.com/legal/bitfinex-pay/end-user-terms" target="_blank">Bitfinex Pay User Terms of service</a></label>
                    <ng-container
                        [ngTemplateOutlet]="formError"
                        [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_terms' | translate,
                                control: KYCForm.controls['terms']
                            }"></ng-container>
                </div>
            </div>
            <button type="submit" class="btn btn-yellow btn-block">{{ 'continue_button' | translate }}</button>
        </form>
    </div>
</div>
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
        <span class="form-error-label">{{ message }}</span>
    </ng-container>
</ng-template>
