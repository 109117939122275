import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ErrorService } from "../../../core/services/error.service";

@Component({
    selector: "app-alert",
    templateUrl: "./alert.component.html",
    styleUrls: ["./alert.component.scss"],
})
export class AlertComponent implements OnInit {
    subscriptions: Subscription[] = [];

    isAlertShown = false;
    type = null;
    message = null;

    constructor(private errorService: ErrorService) {
        this.subscriptions.push(
            this.errorService.alert$$.subscribe((alert) => {
                if (alert !== null) {
                    this.type = alert.type;
                    this.message = alert.message;
                    this.isAlertShown = true;
                } else {
                    this.isAlertShown = false;
                }
            })
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
}
