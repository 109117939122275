import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { paymentNavigationItems } from "../../../core/services/payment.service";

@Component({
    selector: "app-payment-nav",
    templateUrl: "./payment-nav.component.html",
    styleUrls: ["./payment-nav.component.scss"],
})
export class PaymentNavComponent implements OnInit {
    @Output() selectedNavigationItem = new EventEmitter<string>();
    _selectedNavigationItem = "";
    paymentNavigationItems = paymentNavigationItems;

    constructor() {
        this.selectedNavigationItem.subscribe((item) => {
            this._selectedNavigationItem = item;
        });
    }

    ngOnInit() {
        this.selectedNavigationItem.emit(this.paymentNavigationItems.SCAN);
    }

    onSwitchPaymentContent(item: string) {
        if (this._selectedNavigationItem !== item) {
            this.selectedNavigationItem.emit(item);
        }
    }
}
