import { Component, HostListener, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { LocaleService } from './core/services/locale.service';
import { UtilsService } from './core/services/utils.service';
import { environment } from '../environments/environment';
import { TenantService } from './core/services/tenant.service';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  isAppReady = false;
  isMobile = false;
  isMainFooterShown = false;

  constructor(
    private http: HttpClient,
    private renderer: Renderer2,
    private activatedRoute: ActivatedRoute,
    private ngbModalConfig: NgbModalConfig,
    private titleService: Title,
    private utilsService: UtilsService,
    private localeService: LocaleService,
    private tenantService: TenantService
  ) {
    // show loader
    this.utilsService.showLoader();

    // initialize tenant config
    this.subscriptions.push(
      this.tenantService.initialize().subscribe({
        next: (data) => {
          // set page title
          this.titleService.setTitle(data['title']);
        },
        error: () => this.utilsService.showLoadingError()
      })
    );

    // initialize global style
    this.tenantService.config$$.subscribe((tenantConfig) => {
      if (tenantConfig != null) {
        const styleUrl = tenantConfig['global_styles'] ?? null;
        this.tenantService.getGlobalStylesData(styleUrl).subscribe({
          next: (result: any[]) => {
            result.forEach((r: any) => {
              this.tenantService.setStyleAttributes(r);
            });
          },
          complete: () => this.utilsService.setAppToReady(),
          error: () => this.utilsService.showLoadingError()
        });
      }
    });

    // set default locale
    this.localeService.initLocale(environment.defaultLanguage);

    // set global config for modal
    ngbModalConfig.centered = true;
    ngbModalConfig.scrollable = true;
    ngbModalConfig.keyboard = false;

    // set params from url
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.utilsService.urlParams$$.next(params);
      })
    );

    // subscribe to is app ready
    this.subscriptions.push(
      this.utilsService.isAppReady$$.subscribe((isAppReady) => {
        // check window size
        this.getWindowSize();
        // set is app ready
        this.isAppReady = isAppReady;
      })
    );

    // subscribe to is main footer shown
    this.subscriptions.push(
      this.utilsService.isMainFooterShown$$.subscribe((isMainFooterShown) => {
        this.isMainFooterShown = isMainFooterShown;
      })
    );

    // subscribe to is mobile
    this.subscriptions.push(
      this.utilsService.isMobile$$.subscribe((isMobile) => {
        this.isMobile = isMobile;
        if (isMobile) {
          this.renderer.removeClass(document.body, 'gradient');
          this.renderer.addClass(document.body, 'mobile');
        } else {
          this.renderer.removeClass(document.body, 'mobile');
          this.renderer.addClass(document.body, 'gradient');
        }
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  @HostListener('window:resize', ['$event'])
  getWindowSize(event?: any) {
    const windowWidth = window.innerWidth;
    this.utilsService.isMobile$$.next(windowWidth <= 992);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event?: any) {
    return this.utilsService.skipUnloadConfirm$$.value;
  }
}
