import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "app-payment-progress-bar",
    templateUrl: "./payment-progress-bar.component.html",
    styleUrls: ["./payment-progress-bar.component.scss"],
})
export class PaymentProgressBarComponent implements OnInit {
    @Input() progressPercentage: number = 0;
    @Input() progressTime: string = "";

    constructor() {}

    ngOnInit() {}
}
