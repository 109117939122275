import { Component, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { paymentNavigationItems, PaymentService } from '../../../core/services/payment.service';
import { TranslateService } from '@ngx-translate/core';
import { ChargeService } from '../../../core/services/charge.service';
import { paymentSteps, StepService } from '../../../core/services/step.service';
import { Subscription } from 'rxjs';
import { ErrorService } from '../../../core/services/error.service';
import { PaymentMethodModel } from '../../../core/models/payment-method.model';
import { UtilsService } from '../../../core/services/utils.service';
import { HelperService } from '../../../core/services/helper.service';
import { DomSanitizer } from '@angular/platform-browser';
import { paymentStatuses } from '../../../core/services/payment.service';
import { PaymentOptionModel } from '../../../core/models/payment-option.model';
import { ChargeModel } from '../../../core/models/charge.model';
import { PaymentModel } from '../../../core/models/payment.model';
import { ClaimRefundModel } from '../../../core/models/claim-refund.model';
import { TransactionStatusModel } from '../../../core/models/transaction-status.models';
import { PaymentNetworkModel } from '../../../core/models/payment-network.model';

@Component({
  selector: 'app-payment-processor',
  templateUrl: './payment-processor.component.html',
  styleUrls: ['./payment-processor.component.scss']
})
export class PaymentProcessorComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  charge: ChargeModel;
  payment: PaymentModel = new PaymentModel();
  selectedPaymentMethod: PaymentMethodModel;
  selectedPaymentOption = new PaymentOptionModel();
  selectedPaymentNetwork = new PaymentNetworkModel();

  isPaymentLoaderShown = true;
  isPaymentNavShown = false;
  isPaymentCurrencyAlertShown = false;
  isPaymentCurrencyInfoShown = false;
  isPaymentDetailsInfoShown = false;
  isContentTerminatePaymentShown = false;

  isPaymentOverpaid = false;
  isPaymentUnderpaid = false;

  qrData = '';
  amount: number;
  cryptoAmount = '';

  QRCodeIcon = '';
  callbackEndpoint = '';

  paymentStatuses = paymentStatuses;
  paymentStatus: string = paymentStatuses.IN_PROGRESS;
  paymentStatusIcon = '';
  paymentProgressPercentage = 100;
  paymentProgressTime = '';

  paymentNavigationItems = paymentNavigationItems;
  selectedNavigationItem: string = paymentNavigationItems.SCAN;
  deepLinks = {};
  selectedDeepLink = '';
  paymentDescriptionType = '';

  isRedirectShown = false;
  isMobile = false;

  // overpaid
  overpaidStatusIcon = '';
  amountRefund = 0;

  // underpaid
  underpaidStatusIcon = '';

  constructor(
    private translate: TranslateService,
    private chargeService: ChargeService,
    private paymentService: PaymentService,
    private utilsService: UtilsService,
    private stepService: StepService,
    private errorService: ErrorService,
    private clipboard: Clipboard,
    public helperService: HelperService,
    public sanitizing: DomSanitizer
  ) {
    // set charge details
    this.charge = this.chargeService.charge$$.value;

    // init data
    this.overpaidStatusIcon = this.helperService.paymentStatusesIcons[paymentStatuses.OVERPAID];
    this.underpaidStatusIcon = this.helperService.paymentStatusesIcons[paymentStatuses.UNDERPAID];
    this.amount = this.charge.amount / 100;

    // set payment method
    this.selectedPaymentMethod = this.paymentService.selectedPaymentMethod$$.value;

    // subscribe to payment
    this.subscriptions.push(
      this.paymentService.payment$$.subscribe((payment: PaymentModel) => {
        // set payment data
        this.payment = payment;
      })
    );

    // subscribe to selected payment option
    this.subscriptions.push(
      this.paymentService.selectedPaymentOption$$.subscribe((selectedPaymentOption: PaymentOptionModel) => {
        console.log('option: ', selectedPaymentOption);
        // set selected payment option
        this.selectedPaymentOption = selectedPaymentOption;
      })
    );

    // subscribe to selected payment network
    this.subscriptions.push(
      this.paymentService.selectedPaymentNetwork$$.subscribe((selectedPaymentNetwork: PaymentNetworkModel) => {
        console.log('network: ', selectedPaymentNetwork);
        // set selected payment network
        this.selectedPaymentNetwork = selectedPaymentNetwork;
      })
    );

    // subscribe to deep links
    this.subscriptions.push(
      this.paymentService.deepLinks$$.subscribe((deepLinks) => {
        this.deepLinks = deepLinks;
        if (this.selectedPaymentOption && this.selectedPaymentOption.code in deepLinks) {
          this.selectedDeepLink = deepLinks[this.selectedPaymentOption.code];
        } else if (this.selectedPaymentMethod.type in deepLinks) {
          this.selectedDeepLink = deepLinks[this.selectedPaymentMethod.type];
        }
      })
    );

    // subscribe to payment progress percentage
    this.subscriptions.push(
      this.paymentService.paymentProgressPercentage$$.subscribe((paymentProgressPercentage) => {
        this.paymentProgressPercentage = paymentProgressPercentage;
      })
    );

    // subscribe to payment progress time
    this.subscriptions.push(
      this.paymentService.paymentProgressTime$$.subscribe((paymentProgressTime) => {
        this.paymentProgressTime = paymentProgressTime;
      })
    );

    // subscribe to payment status
    this.subscriptions.push(
      this.paymentService.paymentStatus$$.subscribe((status) => {
        // set payment status
        this.paymentStatus = status;
        // set payment status icon
        this.paymentStatusIcon = this.helperService.paymentStatusesIcons[this.paymentStatus];
        // show correct payment status
        if (status !== paymentStatuses.IN_PROGRESS) {
          if (status === paymentStatuses.SUCCESS) {
            this.setPaymentSuccess();
          } else if (status === paymentStatuses.EXPIRED) {
            this.setPaymentTimeout();
            // } else if (status === paymentStatuses.UNDERPAID) {
            //     this.setPaymentUnderpaid();
          } else if (status === paymentStatuses.OVERPAID) {
            this.setPaymentOverpaid();
          } else {
            this.setPaymentFailed();
          }
        }
      })
    );

    // subscribe to transaction status
    this.subscriptions.push(
      this.paymentService.transactionStatus$$.subscribe((transactionStatus: TransactionStatusModel) => {
        // payment refund amount
        const diffAmount = parseFloat(transactionStatus.diff_amount ?? '0');
        this.amountRefund = Math.abs(diffAmount);
        // set claim refund form data
        const claimRefundForm = new ClaimRefundModel();
        claimRefundForm.paymentMethod = this.selectedPaymentMethod;
        claimRefundForm.paymentOption = this.selectedPaymentOption;
        claimRefundForm.transactionId = this.payment.transaction_id;
        claimRefundForm.amountRefund = this.amountRefund;
        claimRefundForm.amountPaid = (this.charge.amount + diffAmount * 100) / 100;
        this.paymentService.claimRefundForm$$.next(claimRefundForm);
        // check if payment is underpaid
        if (this.qrData !== transactionStatus.qr_data && diffAmount < 0) {
          this.stepService.currentStep$$.next({ ...this.stepService.currentStep$$.value, isLocked: true });
          const diffCryptoAmount = parseFloat(transactionStatus.diff_crypto_amount ?? '0');
          this.isPaymentUnderpaid = true;
          this.qrData = transactionStatus.qr_data ?? this.payment.qr_data;
          this.amount = this.amountRefund ?? this.amount;
          this.cryptoAmount = Math.abs(diffCryptoAmount).toString() ?? this.payment.crypto_amount;
        }
      })
    );

    // get content terminate payment info
    this.subscriptions.push(
      this.utilsService.isContentTerminatePaymentShown$$.subscribe((isContentTerminatePaymentShown) => {
        this.isContentTerminatePaymentShown = isContentTerminatePaymentShown;
      })
    );

    // subscribe to is mobile
    this.subscriptions.push(
      this.utilsService.isMobile$$.subscribe((isMobile) => {
        this.isMobile = isMobile;
      })
    );

    // generate payment
    this.generatePayment();
  }

  ngOnInit() {}

  ngOnDestroy() {
    // reset payment
    this.paymentService.resetPayment();
    // unsubscribe
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  generatePayment() {
    this.subscriptions.push(
      this.paymentService
        .generatePayment(this.selectedPaymentMethod, this.selectedPaymentOption, this.selectedPaymentNetwork)
        .subscribe({
          next: (payment: PaymentModel) => {
            // make redirect if link exists
            if (payment.redirect_url) {
              this.utilsService.skipUnloadConfirm$$.next(true);
              window.location.href = payment.redirect_url;
            } else {
              // set payment data
              this.paymentService.payment$$.next(payment);
              // set qr data
              this.qrData = payment.qr_data;
              // set crypto amount
              this.cryptoAmount = payment.crypto_amount;
              // get QR code icon
              this.QRCodeIcon = this.paymentService.getQRCodeIcon();
              // check if selected payment option is CRYPTO_CURRENCY
              if (this.selectedPaymentOption.section === 'CRYPTO_CURRENCY') {
                // show payment navigation bar
                this.isPaymentNavShown = true;
                // show payment currency alert
                this.isPaymentCurrencyAlertShown = true;
                // show payment currency info
                this.isPaymentCurrencyInfoShown = true;
              }
              // set initial payment progress time
              this.setInitialPaymentProgress(payment);
              // start payment timer
              this.paymentService.startTransactionStatusInterval(true, payment.transaction_id);
              // set payment description
              this.setPaymentDescription();
              // hide loader
              this.isPaymentLoaderShown = false;
            }
          },
          error: (e) => this.setContentError(e.error.code)
        })
    );
  }

  setSelectedNavigationItem(item: string) {
    this.selectedNavigationItem = item;
  }

  setPaymentDescription() {
    if (this.selectedPaymentOption.code && this.selectedPaymentNetwork) {
      this.paymentDescriptionType = `${this.selectedPaymentOption.code}-${this.selectedPaymentNetwork.network_type}`;
    } else if (this.selectedPaymentOption.code) {
      this.paymentDescriptionType = this.selectedPaymentOption.code;
    } else {
      this.paymentDescriptionType = this.selectedPaymentMethod.type;
    }
  }

  setInitialPaymentProgress(payment: PaymentModel) {
    // set payment full time
    this.paymentService.paymentFullTime$$.next(
      this.utilsService.timeDifference(payment.expires_at, payment.created_at)
    );
    // set payment time left
    this.paymentService.paymentTimeLeft$$.next(
      this.utilsService.timeDifference(payment.expires_at, this.utilsService.getUTCTimestamp())
    );
    // set payment progress time
    this.paymentService.paymentProgressTime$$.next(
      this.utilsService.parseTime(this.paymentService.paymentTimeLeft$$.value)
    );
  }

  setPaymentSuccess() {
    // set payment status
    this.paymentStatus = paymentStatuses.SUCCESS;
    // reset payment elements
    this.resetPaymentCommonElements();
    // remove selected payment option
    // this.paymentService.selectedPaymentOption$$.next(new PaymentOptionModel());
    // skip unload confirm
    this.utilsService.skipUnloadConfirm$$.next(true);
    // set timer to delete charge and redirect user to callback endpoint
    setTimeout(() => {
      this.callbackEndpoint = this.utilsService.modifyUrl(
        this.charge.callback_endpoint,
        `payment_method=${this.payment.payment_method}&payment_status=${this.paymentStatus}&transaction_id=${this.payment.transaction_id}`
      );
      this.chargeService.deleteCharge().subscribe({
        error: () => (window.location.href = this.callbackEndpoint),
        complete: () => (window.location.href = this.callbackEndpoint)
      });
    }, 2000);
    // set timer to show redirect button if user is not redirected
    setTimeout(() => {
      this.isRedirectShown = true;
    }, 4000);
  }

  setPaymentTimeout() {
    // set payment status
    this.paymentStatus = paymentStatuses.EXPIRED;
    // reset payment elements
    this.resetPaymentCommonElements();
    // show content terminate payment
    this.utilsService.showContentTerminatePayment();
  }

  // setPaymentUnderpaid() {
  //     // set payment status
  //     this.paymentStatus = paymentStatuses.UNDERPAID;
  //     // reset payment elements
  //     this.resetPaymentCommonElements();
  // }

  setPaymentOverpaid() {
    // terminate transaction status interval
    this.paymentService.terminateTransactionStatusInterval();
    this.resetPaymentCommonElements();
    this.isPaymentOverpaid = true;
  }

  setPaymentFailed() {
    // set payment status
    this.paymentStatus = paymentStatuses.FAILED;
    // reset payment elements
    this.resetPaymentCommonElements();
    // show content terminate payment
    this.utilsService.showContentTerminatePayment();
  }

  setContentError(errorCode: string) {
    const errorDetails = this.errorService.getErrorDetails(errorCode);
    this.isPaymentLoaderShown = false;
    this.errorService.setAlert('info', this.translate.instant(`errors.${errorDetails['code']}`));
    this.stepService.toStep(errorDetails['to_step']);
  }

  onTerminatePayment() {
    this.utilsService.showBoxLoader();
    this.paymentService.terminateTransactionStatusInterval();
    const paymentMethod = this.paymentService.selectedPaymentMethod$$.value.type;
    const callbackEndpoint = this.utilsService.modifyUrl(
      this.chargeService.charge$$.value.callback_endpoint,
      `payment_method=${paymentMethod}&payment_status=${paymentStatuses.TERMINATED}`
    );
    this.utilsService.skipUnloadConfirm$$.next(true);
    this.paymentService.terminatePayment().subscribe({
      error: () => (window.location.href = callbackEndpoint),
      complete: () => (window.location.href = callbackEndpoint)
    });
  }

  onRetry() {
    // reset payment
    this.paymentService.resetPayment();
    // show payment loader
    this.isPaymentLoaderShown = true;
    // reset previous step button
    this.stepService.currentStep$$.next({ ...this.stepService.currentStep$$.value, isLocked: false });
    // show terminate payment
    this.utilsService.showTerminatePayment();
    // hide content terminate payment
    this.utilsService.hideContentTerminatePayment();
    // generate payment
    this.generatePayment();
  }

  onOpenDeepLink() {
    this.utilsService.skipUnloadConfirm$$.next(true);
    window.location.href = this.selectedDeepLink;
  }

  onCopyText(text: string, type: string) {
    this.errorService.hideAlert();
    this.clipboard.copy(text);
    this.errorService.setAlert('info', this.translate.instant(`copy_success_${type}`));
  }

  onClaimRefundForm() {
    this.stepService.toStep(paymentSteps.PAYMENT_CLAIM_REFUND);
  }

  onSurcharge() {
    this.isPaymentUnderpaid = false;
    this.stepService.currentStep$$.next({ ...this.stepService.currentStep$$.value, isLocked: false });
  }

  resetPaymentCommonElements() {
    // hide previous step button
    this.stepService.currentStep$$.next({ ...this.stepService.currentStep$$.value, isLocked: true });
    // hide terminate payment button
    this.utilsService.hideTerminatePayment();
    // hide payment nav
    this.isPaymentNavShown = false;
    // hide payment currency alert
    this.isPaymentCurrencyAlertShown = false;
    // hide payment currency info
    this.isPaymentCurrencyInfoShown = false;
    // hide payment details info
    this.isPaymentDetailsInfoShown = false;
    // set default payment nav item
    this.selectedNavigationItem = paymentNavigationItems.SCAN;
    // remove selected payment option
    // this.paymentService.selectedPaymentOption$$.next(new PaymentOptionModel());
    // remove deep link button
    this.selectedDeepLink = '';
    // remove payment description
    this.paymentDescriptionType = '';
  }
}
