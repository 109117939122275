<div class="payment-claim-refund-wrapper">
    <!--<div class="previous-step-wrapper" (click)="onPaymentProcessorForm()">
        <div class="previous-step-icon"></div>
        <div class="previous-step-hover-text">{{ 'back_btn' | translate }}</div>
    </div>-->
    <div class="payment-title">{{ 'claim_refund.title' | translate }}</div>
    <div class="payment-wrapper">
        <!-- SUCCESS -->
        <ng-container *ngIf="isClaimRefundSuccess">
            <div class="payment-details-title">{{ 'claim_refund.success_title' | translate }}</div>
            <img [src]="sanitizing.bypassSecurityTrustUrl(successStatusIcon)" class="payment-status-icon"
                 alt="payment-status-icon" />
            <div
                class="payment-claim-refund-description text-black text-center mb-60">{{ 'claim_refund.success_description' | translate }}</div>
            <div class="payment-details-description">{{ 'payment.status_description' | translate }}</div>
            <div *ngIf="isRedirectShown" class="payment-details-redirect-info"
                 [innerHTML]="'payment.redirect_details' | translate : {callback_endpoint: callbackEndpoint}"></div>
        </ng-container>
        <!-- END -->
        <!-- FORM -->
        <ng-container *ngIf="!isClaimRefundSuccess">
            <div class="payment-claim-refund-description">{{ 'claim_refund.description' | translate }}</div>
            <div class="payment-claim-refund-amount">
                <div class="payment-claim-refund-amount-item">
                    <span>{{ 'claim_refund.amount_paid_label' | translate }}</span>
                    <span>{{ charge.currency.display_symbol }} {{ claimRefund.amountPaid }}</span>
                </div>
                <div class="payment-claim-refund-amount-item">
                    <span>{{ 'claim_refund.amount_refund_label' | translate }}</span>
                    <span>{{ charge.currency.display_symbol }} {{ claimRefund.amountRefund }}</span>
                </div>
            </div>
            <div class="form-group form-group--details">
                <label>{{ 'claim_refund.asset_label' | translate }}</label>
                <div class="input-group input-group--details">
                    <div class="input-group-prepend">
                        <div class="prepend__bg" [style]="{background: 'url(' + paymentOptionIcon
                        + ')'}"></div>
                        <img [src]="paymentOptionIcon" alt="icon" />
                    </div>
                    <input type="text" class="form-control form-control-disabled"
                           value="{{ selectedPaymentOption.name }} {{ selectedPaymentOption.code }}"
                           disabled />
                </div>
            </div>
            <div class="form-group form-group--details">
                <label>{{ 'claim_refund.network_label' | translate }}</label>
                <div class="input-group input-group--details">
                    <div class="input-group-prepend">
                        <div class="prepend__bg" [style]="{background: 'url(' + networkIcon
                        + ')'}"></div>
                        <img [src]="networkIcon" alt="icon" />
                    </div>
                    <input type="text" class="form-control form-control-disabled"
                           value="{{ selectedPaymentNetwork.full_name }}" disabled />
                </div>
            </div>
            <form [formGroup]="claimRefundForm" (submit)="onClaimRefundSubmit()">
                <div class="form-group" [ngClass]="displayErrorFieldCSS('email')">
                    <label>{{ 'claim_refund.email_label' | translate }}</label>
                    <div class="input__wrap">
                        <input type="text" name="email" formControlName="email" class="form-control"
                               placeholder="{{ 'claim_refund.email_placeholder' | translate }}" />
                        <button *ngIf="claimRefundForm.controls['email'].value" type="button" aria-label="Reset Email" (click)="resetField('email')" class="input__reset">&#x2715;</button>
                    </div>
                    <ng-container
                        [ngTemplateOutlet]="formError"
                        [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: claimRefundForm.controls['email']
                            }"></ng-container>
                    <ng-container
                        [ngTemplateOutlet]="formError"
                        [ngTemplateOutletContext]="{
                                validation: 'email',
                                message: 'errors.email_invalid_format' | translate,
                                control: claimRefundForm.controls['email']
                            }"></ng-container>
                </div>
                <div class="form-group" [ngClass]="displayErrorFieldCSS('address')">
                    <label>{{ 'claim_refund.deposit_address_label' | translate : { currency_name: claimRefund.paymentOption.name } }}</label>
                    <div class="textarea__wrap">
                        <textarea name="address" formControlName="address" class="form-control" rows="3"
                                  placeholder="{{ 'claim_refund.deposit_address_placeholder' | translate : { currency_name: claimRefund.paymentOption.name } }}"></textarea>
                        <button *ngIf="claimRefundForm.controls['address'].value" type="button" aria-label="Reset Email" (click)="resetField('address')" class="textarea__reset input__reset">&#x2715;</button>
                    </div>
                    <ng-container
                        [ngTemplateOutlet]="formError"
                        [ngTemplateOutletContext]="{
                                validation: 'required',
                                message: 'errors.require_field' | translate,
                                control: claimRefundForm.controls['address']
                            }"></ng-container>
                </div>
                <button type="submit" class="btn btn-yellow btn-block">{{ 'claim_refund_btn' | translate }}</button>
            </form>
        </ng-container>
        <!-- END -->
    </div>
    <div *ngIf="!isClaimRefundSuccess" class="payment-description-wrapper">
        <p>{{ 'claim_refund.help_description' | translate }}</p>
        <a *ngIf="supportEmail" href="mailto:{{ supportEmail }}" class="btn-link-primary">{{ supportEmail }}</a>
    </div>
</div>
<ng-template #formError let-control="control" let-message="message" let-validation="validation">
    <ng-container *ngIf="control.hasError(validation) && (control.dirty || control.touched)">
        <span class="form-error-label">{{ message }}</span>
    </ng-container>
</ng-template>
