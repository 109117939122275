<div class="payment-processor-wrapper">
    <div class="payment-title">{{ 'payment.title' | translate : { name: helperService.getPaymentTitle() } }} {{selectedPaymentNetwork ? '('+selectedPaymentNetwork.short_name+')' : ''}}</div>
    <div class="payment-wrapper">
        <!-- PAYMENT LOADER -->
        <app-payment-loader [isShown]="isPaymentLoaderShown"></app-payment-loader>
        <!-- END -->
        <!-- OVERPAID PAYMENT -->
        <ng-container *ngIf="isPaymentOverpaid">
            <div class="payment-overpaid-wrapper">
                <div class="payment-details-title">{{ 'payment.success_title' | translate }}</div>
                <img [src]="sanitizing.bypassSecurityTrustUrl(overpaidStatusIcon)" class="payment-overpaid-icon"
                     alt="overpaid-icon" />
                <div class="payment-overpaid-amount">
                    <span>{{ 'payment.overpaid_amount_description' | translate }}</span>
                    <span>{{ charge.currency.display_symbol }} {{ amountRefund | currency: charge.currency.code:'':'1.2-2' }}</span>
                </div>
                <div
                    class="payment-overpaid-description text-center">{{ 'payment.overpaid_description' | translate }}</div>
                <button class="btn btn-yellow btn-block"
                        (click)="onClaimRefundForm()">{{ 'claim_refund_form_btn' | translate }}</button>
            </div>
        </ng-container>
        <!-- END -->
        <!-- UNDERPAID PAYMENT -->
        <ng-container *ngIf="isPaymentUnderpaid">
            <div class="payment-underpaid-wrapper">
                <div class="payment-details-title">{{ 'payment.underpaid_title' | translate }}</div>
                <img [src]="sanitizing.bypassSecurityTrustUrl(underpaidStatusIcon)" class="payment-underpaid-icon"
                     alt="underpaid-icon" />
                <div class="payment-underpaid-amount">
                    <span>{{ 'payment.underpaid_amount_description' | translate }}</span>
                    <span>{{ charge.currency.display_symbol }} {{ amount | currency: charge.currency.code:'':'1.2-2' }}</span>
                    <span>{{ 'payment.underpaid_amount_total_preview' | translate : {
                        currency_display_symbol: charge.currency.display_symbol,
                        amount: charge.amount / 100 | currency: charge.currency.code:'':'1.2-2'
                    } }}</span>
                </div>
                <div
                    class="payment-underpaid-description text-center">{{ 'payment.underpaid_description' | translate }}</div>
                <div class="payment-underpaid-actions">
                    <button class="btn btn-yellow btn-block"
                            (click)="onSurcharge()">{{ 'surcharge_btn' | translate }}</button>
                    <button class="btn btn-secondary btn-block"
                            (click)="onClaimRefundForm()">{{ 'claim_refund_form_btn' | translate }}</button>
                </div>
            </div>
        </ng-container>
        <!-- END -->
        <!-- PAYMENT -->
        <ng-container *ngIf="payment.transaction_id && !isPaymentOverpaid && !isPaymentUnderpaid">
            <!-- PAYMENT NAVIGATION -->
            <app-payment-nav *ngIf="isPaymentNavShown"
                             (selectedNavigationItem)="setSelectedNavigationItem($event)"></app-payment-nav>
            <!-- END -->
            <div class="payment-inside-wrapper">
                <!-- PAYMENT TITLE (per different status) -->
                <ng-container *ngIf="paymentStatus === paymentStatuses.IN_PROGRESS">
                    <div *ngIf="selectedNavigationItem === paymentNavigationItems.SCAN"
                         class="payment-details-title">{{ 'payment.scan_qr_code_title' | translate }}</div>
                    <div *ngIf="selectedNavigationItem === paymentNavigationItems.MANUAL"
                         class="payment-details-title">{{ 'payment.manual_copy_title' | translate }}</div>
                    <app-payment-progress-bar [progressPercentage]="paymentProgressPercentage"
                                              [progressTime]="paymentProgressTime"></app-payment-progress-bar>
                </ng-container>
                <ng-container *ngIf="paymentStatus === paymentStatuses.SUCCESS">
                    <div class="payment-details-title">{{ 'payment.success_title' | translate }}</div>
                </ng-container>
                <ng-container *ngIf="paymentStatus === paymentStatuses.EXPIRED">
                    <div class="payment-details-title">{{ 'payment.error_title' | translate }}</div>
                </ng-container>
                <ng-container *ngIf="paymentStatus === paymentStatuses.FAILED">
                    <div class="payment-details-title">{{ 'payment.error_title' | translate }}</div>
                </ng-container>
                <!-- END -->
                <!-- PAYMENT CONTENT -->
                <ng-container [ngSwitch]="selectedNavigationItem">
                    <!-- QR CODE -->
                    <ng-container *ngSwitchCase="paymentNavigationItems.SCAN">
                        <div class="qr-code-wrapper payment-{{ paymentStatus }}">
                            <qrcode-svg
                                [value]="qrData"
                                [margin]="0"
                                [errorCorrectionLevel]="'M'">
                            </qrcode-svg>
                            <div class="qr-code-icon" *ngIf="QRCodeIcon">
                                <img src="{{ QRCodeIcon }}" alt="qrcode" />
                            </div>
                            <div *ngIf="paymentStatus !== paymentStatuses.IN_PROGRESS" class="payment-status-wrapper">
                                <img
                                    [src]="sanitizing.bypassSecurityTrustUrl(paymentStatusIcon)"
                                    alt="payment-status-icon" />
                            </div>
                        </div>
                    </ng-container>
                    <!-- END -->
                    <!-- MANUAL COPY -->
                    <ng-container *ngSwitchCase="paymentNavigationItems.MANUAL">
                        <div class="manual-copy-wrapper">
                            <div class="form-group">
                                <label>{{ 'copy_crypto_address_label' | translate }}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control form-control-readonly"
                                           value="{{ payment.crypto_address | slice:0:9 }}...{{ payment.crypto_address | slice:-5 }}"
                                           readonly>
                                    <div class="input-group-append">
                                        <a class="btn" type="button"
                                           (click)="onCopyText(payment.crypto_address, 'crypto_address')"><span>{{ 'copy_btn' | translate }}</span></a>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label>{{ 'copy_crypto_amount_label' | translate }}</label>
                                <div class="input-group">
                                    <input type="text" class="form-control form-control-readonly"
                                           value="{{ cryptoAmount }} {{ selectedPaymentOption.code }}"
                                           readonly>
                                    <div class="input-group-append">
                                        <a class="btn" type="button"
                                           (click)="onCopyText(cryptoAmount, 'crypto_amount')"><span>{{ 'copy_btn' | translate }}</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- END -->
                </ng-container>
                <!-- END -->
                <!-- DEEP LINK -->
                <ng-container *ngIf="isMobile && selectedDeepLink">
                    <button class="btn btn-primary btn-yellow mt-4 btn-block" (click)="onOpenDeepLink()">
                        {{ 'deep_link_button' | translate : { payment_option_name: selectedPaymentOption.name } }}
                    </button>
                </ng-container>
                <!-- END -->
                <!-- PAYMENT CURRENCY ALERT -->
                <app-payment-currency-alert *ngIf="isPaymentCurrencyAlertShown"></app-payment-currency-alert>
                <!-- END -->
                <!-- PAYMENT STATUS -->
                <ng-container *ngIf="paymentStatus === paymentStatuses.SUCCESS">
                    <div class="payment-details-description">{{ 'payment.status_description' | translate }}</div>
                    <div *ngIf="isRedirectShown" class="payment-details-redirect-info"
                         [innerHTML]="'payment.redirect_details' | translate : {callback_endpoint: callbackEndpoint}"></div>
                </ng-container>
                <ng-container *ngIf="paymentStatus === paymentStatuses.EXPIRED">
                    <div class="payment-details-description">{{ 'payment.expired_description' | translate }}</div>
                    <button class="btn btn-secondary btn-block payment-status-btn" (click)="onRetry()">
                        <span class="icon-reload"></span>{{ 'retry_btn' | translate }}
                    </button>
                </ng-container>
                <ng-container *ngIf="paymentStatus === paymentStatuses.FAILED">
                    <button class="btn btn-secondary btn-block payment-status-btn" (click)="onRetry()">
                        <span class="icon-reload"></span>{{ 'retry_btn' | translate }}
                    </button>
                </ng-container>
                <!-- END -->
                <!-- TERMINATE PAYMENT -->
                <div *ngIf="isContentTerminatePaymentShown" class="terminate-payment-wrapper">
                    <button type="button" (click)="onTerminatePayment()" class="btn btn-link btn-link-primary btn-cancel-payment">
                        {{ 'payment.terminate_payment_btn' | translate }}
                    </button>
                </div>
                <!-- END -->
            </div>
            <!-- PAYMENT CURRENCY INFO -->
            <app-payment-currency-info *ngIf="isPaymentCurrencyInfoShown" [amount]="amount"
                                       [cryptoAmount]="cryptoAmount"></app-payment-currency-info>
            <!-- END -->
            <!-- POWERED BY -->
            <app-powered-by-bitfinex
                *ngIf="selectedPaymentOption.powered_by === 'BITFINEX' || (selectedPaymentNetwork && selectedPaymentNetwork.powered_by === 'BITFINEX')"></app-powered-by-bitfinex>
            <!-- END -->
        </ng-container>
        <!-- END -->
    </div>
</div>
<div *ngIf="paymentDescriptionType" class="payment-description-wrapper">
    <p [innerHTML]="'payment.description.' + paymentDescriptionType + '.text' | translate"></p>
    <a *ngIf="selectedPaymentOption.code"
       href="{{ 'payment.description.' + paymentDescriptionType + '.how_to_link' | translate }}"
       class="btn-link-primary" target="_blank">{{ 'payment.how_to_pay' | translate }}</a>
</div>
