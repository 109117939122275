import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentMethodModel } from '../../../core/models/payment-method.model';
import { ChargeService } from '../../../core/services/charge.service';
import { UtilsService } from '../../../core/services/utils.service';
import { paymentSteps, StepService } from '../../../core/services/step.service';
import { PaymentService } from '../../../core/services/payment.service';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss']
})
export class PaymentMethodsComponent implements OnInit {
  paymentMethods: PaymentMethodModel[] = [];
  paymentMethod: PaymentMethodModel;

  constructor(
    private ngbModal: NgbModal,
    private chargeService: ChargeService,
    private utilsService: UtilsService,
    private stepService: StepService,
    private paymentService: PaymentService
  ) {
    this.utilsService.hideContentTerminatePayment();
    this.utilsService.showTerminatePayment();
    this.paymentMethods = this.chargeService.paymentMethods$$.value;
    this.paymentMethod = this.chargeService.charge$$.value['payment_method'];
  }

  ngOnInit() {
    // check if only one method exists
    if (this.paymentMethods.length === 1) {
      this.paymentMethod = this.paymentMethods[0];
    }
    if (this.paymentMethod) {
      this.paymentService.selectedPaymentMethod$$.next(this.paymentMethod);
      if (this.paymentMethod.payment_options.length === 0) {
        setTimeout(() => {
          this.stepService.toStep(paymentSteps.PAYMENT_PROCESSOR);
        }, 0);
      } else {
        setTimeout(() => {
          this.stepService.toStep(paymentSteps.PAYMENT_OPTIONS);
        }, 0);
      }
    }
  }
}
