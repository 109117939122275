import { PaymentMethodModel } from "./payment-method.model";

export class PaymentModel {
    qr_data: string;
    payment_methods: PaymentMethodModel[];
    payment_method: PaymentMethodModel | null;
    crypto_amount: string;
    crypto_address: string;
    payment_code: string;
    transaction_id: string;
    redirect_url: string;
    created_at: number;
    expires_at: number;

    constructor() {
        this.qr_data = "";
        this.payment_methods = [];
        this.payment_method = null;
        this.crypto_amount = "";
        this.crypto_address = "";
        this.payment_code = "";
        this.transaction_id = "";
        this.redirect_url = "";
        this.created_at = 0;
        this.expires_at = 0;
    }
}
