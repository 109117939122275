import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ChargeService } from "../../../../core/services/charge.service";
import { UtilsService } from "../../../../core/services/utils.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperService } from "../../../../core/services/helper.service";

@Component({
    selector: "app-payment-static-error",
    templateUrl: "./payment-static-error.component.html",
    styleUrls: ["./payment-static-error.component.scss"],
})
export class PaymentStaticErrorComponent implements OnInit {
    urlParams: any;
    charge: any;
    callbackEndpoint: string;
    isRedirectShown = false;

    constructor(
        private router: Router,
        private utilsService: UtilsService,
        private chargeService: ChargeService,
        public helperService: HelperService,
        public sanitizing: DomSanitizer
    ) {
        // hide content loader
        this.utilsService.hideContentLoader();

        // hide terminate button
        this.utilsService.hideTerminatePayment();

        // set url params
        this.urlParams = this.utilsService.urlParams$$.value;

        // get charge details
        this.charge = chargeService.charge$$.value;

        // set callback endpoint
        this.callbackEndpoint = this.utilsService.modifyUrl(
            this.charge.callback_endpoint,
            `payment_method=${this.urlParams["payment_method"]}&payment_status=${this.urlParams["payment_status"]}&transaction_id=${this.urlParams["transaction_id"]}`
        );

        // redirect user to callback endpoint
        setTimeout(() => {
            window.location.href = this.callbackEndpoint;
        }, 2000);

        setTimeout(() => {
            this.isRedirectShown = true;
        }, 4000);
    }

    ngOnInit() {}

    // onReturnToStore() {
    //     window.location.href = this.utilsService.modifyUrl(
    //         this.charge['callback_endpoint'],
    //         `payment_method=CARD&payment_status=${this.urlParams['payment_status']}`
    //     );
    // }
}
