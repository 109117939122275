<div class="page-container">
    <h1 class="page-title mt-30">{{ 'pages.terms.title' | translate }}</h1>
    <div class="page-date mb-30">{{ 'pages.terms.date' | translate }}</div>
    <p [innerHtml]="'pages.terms.description.0' | translate"></p>
    <p [innerHtml]="'pages.terms.description.1' | translate"></p>
    <p [innerHtml]="'pages.terms.description.2' | translate"></p>
    <ngb-accordion [closeOthers]="true">
        <ngb-panel id="terms-acc-1" title="{{ 'pages.terms.content.0.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.0.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="terms-acc-2" title="{{ 'pages.terms.content.1.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.1.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="terms-acc-3" title="{{ 'pages.terms.content.2.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.2.description.0' | translate"></p>
                <p [innerHtml]="'pages.terms.content.2.description.1' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="terms-acc-4" title="{{ 'pages.terms.content.3.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.3.description.0' | translate"></p>
                <p [innerHtml]="'pages.terms.content.3.description.1' | translate"></p>
                <p [innerHtml]="'pages.terms.content.3.description.2' | translate" class="mb-5"></p>
                <ul>
                    <li [innerHtml]="'pages.terms.content.3.list.0' | translate"></li>
                    <li [innerHtml]="'pages.terms.content.3.list.1' | translate"></li>
                    <li [innerHtml]="'pages.terms.content.3.list.2' | translate"></li>
                    <li [innerHtml]="'pages.terms.content.3.list.3' | translate"></li>
                </ul>
                <p [innerHtml]="'pages.terms.content.3.description.3' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="terms-acc-5" title="{{ 'pages.terms.content.4.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.4.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="terms-acc-6" title="{{ 'pages.terms.content.5.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.5.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="terms-acc-7" title="{{ 'pages.terms.content.6.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.6.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="terms-acc-8" title="{{ 'pages.terms.content.7.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.7.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
        <ngb-panel id="terms-acc-9" title="{{ 'pages.terms.content.8.title' | translate }}">
            <ng-template ngbPanelContent>
                <p [innerHtml]="'pages.terms.content.8.description.0' | translate"></p>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
    <p class="mt-30">{{ 'powered_by_clean' | translate }}</p>
</div>
