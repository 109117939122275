import { Component, OnInit } from "@angular/core";
import { PaymentNetworkModel } from "../../../core/models/payment-network.model";
import { UtilsService } from "../../../core/services/utils.service";
import { ChargeService } from "../../../core/services/charge.service";
import { PaymentService } from "../../../core/services/payment.service";
import { PaymentOptionModel } from "../../../core/models/payment-option.model";

@Component({
    selector: "app-payment-networks",
    templateUrl: "./payment-networks.component.html",
    styleUrls: ["./payment-networks.component.scss"],
})
export class PaymentNetworksComponent implements OnInit {
    selectedPaymentOption: PaymentOptionModel;
    paymentNetworks: PaymentNetworkModel[] = [];

    constructor(
        private utilsService: UtilsService,
        private chargeService: ChargeService,
        private paymentService: PaymentService
    ) {
        this.utilsService.hideContentTerminatePayment();
        this.utilsService.showTerminatePayment();
        this.selectedPaymentOption = this.paymentService.selectedPaymentOption$$.value;
        this.paymentNetworks = this.selectedPaymentOption.payment_networks;
    }

    ngOnInit(): void {}
}
