import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { PaymentService } from "./payment.service";
import { UrlSerializer } from "@angular/router";
import { environment } from "../../../environments/environment";
import { TenantService } from "./tenant.service";

@Injectable({
    providedIn: "root",
})
export class HelperService {
    paymentStatusesIcons: any = {
        SUCCESS: this.getDocumentProperty("--success-icon-url"),
        OVERPAID: this.getDocumentProperty("--overpaid-icon-url"),
        UNDERPAID: this.getDocumentProperty("--underpaid-icon-url"),
        EXPIRED: this.getDocumentProperty("--timeout-icon-url"),
        FAILED: this.getDocumentProperty("--error-icon-url"),
    };

    constructor(
        private urlSerializer: UrlSerializer,
        private translate: TranslateService,
        private paymentService: PaymentService,
        private tenantService: TenantService
    ) {}

    getPaymentTitle() {
        if (this.paymentService.selectedPaymentOption$$.value.name) {
            return this.paymentService.selectedPaymentOption$$.value.name;
        }
        if (this.paymentService.selectedPaymentMethod$$.value.type === "CARD") {
            return this.translate.instant("payment_method_card");
        }
        return this.paymentService.selectedPaymentMethod$$.value.name;
    }

    replaceAll(str: string, find: string, replace: string) {
        let escapedFind = find.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
        return str.replace(new RegExp(escapedFind, "g"), replace);
    }

    getDocumentProperty(property: string) {
        const _document = getComputedStyle(document.documentElement);
        let propertyValue = _document.getPropertyValue(property);
        let propertyUrl = propertyValue.match(/\bhttps?:\/\/\S+/gi);
        if (property.endsWith("-url") && propertyUrl) {
            propertyValue = this.replaceAll(propertyUrl[0], '"', "");
        }
        return propertyValue;
    }

    getSupportEmail(currentLang: string) {
        const supportEmails = this.tenantService.config$$.value.support_emails ?? null;
        if (supportEmails) {
            const lang = currentLang in supportEmails ? currentLang : environment.defaultLanguage;
            if (lang in supportEmails) {
                return supportEmails[lang];
            }
        }
        return null;
    }
}
