import { Component, OnInit } from "@angular/core";
import { PaymentOptionModel } from "../../../core/models/payment-option.model";
import { PaymentService } from "../../../core/services/payment.service";
import { PaymentNetworkModel } from "../../../core/models/payment-network.model";

@Component({
    selector: "app-payment-currency-alert",
    templateUrl: "./payment-currency-alert.component.html",
    styleUrls: ["./payment-currency-alert.component.scss"],
})
export class PaymentCurrencyAlertComponent implements OnInit {
    selectedPaymentOption: PaymentOptionModel;
    selectedPaymentNetwork: PaymentNetworkModel;
    networkIcon = "";

    constructor(private paymentService: PaymentService) {
        this.selectedPaymentOption = this.paymentService.selectedPaymentOption$$.value;
        this.selectedPaymentNetwork = this.paymentService.selectedPaymentNetwork$$.value;
    }

    ngOnInit() {
        const pnType = this.selectedPaymentNetwork.network_type?.toLowerCase();
        this.networkIcon = `assets/icons/payment-networks/network-${pnType}-secondary-light-icon.svg`;
    }
}
