import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AnimationOptions } from "ngx-lottie";
import { UtilsService } from "../../../core/services/utils.service";

@Component({
    selector: "app-loader",
    templateUrl: "./loader.component.html",
    styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    isLoaderShown = false;
    isLoadingError = false;
    loaderOptions: AnimationOptions = {
        path: "/assets/loader.json",
    };

    constructor(private utilsService: UtilsService) {
        this.subscriptions.push(
            this.utilsService.isLoaderShown$$.subscribe((isLoaderShown) => {
                this.isLoaderShown = isLoaderShown;
            })
        );

        this.subscriptions.push(
            this.utilsService.isLoadingError$$.subscribe((isLoadingError) => {
                this.isLoadingError = isLoadingError;
            })
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    onReload() {
        location.reload();
    }
}
