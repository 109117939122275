import { Component, Input, OnInit } from '@angular/core';
import { ChargeService } from '../../../core/services/charge.service';
import { ChargeModel } from '../../../core/models/charge.model';
import { PaymentOptionModel } from '../../../core/models/payment-option.model';
import { PaymentService } from '../../../core/services/payment.service';
import { PaymentNetworkModel } from '../../../core/models/payment-network.model';

@Component({
  selector: 'app-payment-currency-info',
  templateUrl: './payment-currency-info.component.html',
  styleUrls: ['./payment-currency-info.component.scss']
})
export class PaymentCurrencyInfoComponent implements OnInit {
  @Input() amount: number = 0;
  @Input() cryptoAmount: string = '';

  charge: ChargeModel;
  selectedPaymentOption: PaymentOptionModel;
  selectedPaymentNetwork: PaymentNetworkModel;
  paymentOptionIcon = '';
  selectedNetworkIcon = '';

  constructor(private chargeService: ChargeService, private paymentService: PaymentService) {
    this.charge = this.chargeService.charge$$.value;
    this.selectedPaymentOption = this.paymentService.selectedPaymentOption$$.value;
    this.selectedPaymentNetwork = this.paymentService.selectedPaymentNetwork$$.value;
  }

  ngOnInit() {
    this.paymentOptionIcon = `assets/icons/payment-options/${this.selectedPaymentOption.code.toLowerCase()}-${this.selectedPaymentOption.section.toLowerCase()}-icon.svg`;
    this.setNetworkIcon();
  }

  setNetworkIcon() {
    this.selectedNetworkIcon = `assets/icons/payment-networks/network-${this.selectedPaymentNetwork.short_name.toLowerCase()}-secondary-icon.svg`;
  }
}
