<div class="error-wrapper">
    <div class="error-container">
        <div class="error-icon">
            <img [src]="sanitizing.bypassSecurityTrustUrl(icon)" alt="404" />
<!--            <ng-lottie width="160px" height="160px" [options]="iconOptions"></ng-lottie>-->
        </div>
        <div class="error-title">{{ 'not_found.title' | translate }}</div>
        <div class="error-description">{{ 'not_found.description' | translate }}</div>
    </div>
</div>
