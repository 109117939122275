import { Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocaleService } from "./locale.service";
import { ActivatedRoute } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { UtilsService } from "./utils.service";

@Injectable({
    providedIn: "root",
})
export class TenantService {
    config$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    API_ENDPOINT = "";

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private localeService: LocaleService,
        private utilsService: UtilsService
    ) {}

    initialize() {
        return this.setConfig();
    }

    setConfig() {
        return this.http
            .get(`${environment.publicEndpoint}/config/pay-gateway/`, {
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    "X-PAY-GATEWAY-HOST": this.utilsService.getBaseUrl() ?? "",
                }),
            })
            .pipe(
                map((data: any) => {
                    // set config
                    this.config$$.next(data);
                    // set endpoint
                    this.API_ENDPOINT = data["api_endpoint"];
                    // set languages
                    this.localeService.languages$$.next(data["languages"]);
                    // return config data
                    return data;
                }),
                catchError(() => {
                    return throwError(() => "Something went wrong by loading config");
                })
            );
    }

    getGlobalStylesData(styleUrl: string): any {
        const requests = [this.http.get(environment.globalStylesDefaultUrl)];
        if (styleUrl) {
            requests.push(this.http.get(styleUrl));
        }
        requests.forEach((r) => {
            r.pipe(
                catchError(() => {
                    return throwError(() => "Something went wrong by loading styles");
                })
            );
        });
        return forkJoin(requests);
    }

    setStyleAttributes(styles: object) {
        Object.entries(styles).forEach(([attributeKey, attributeValue]) => {
            this.utilsService.setDocumentProperty(attributeKey, String(attributeValue));
        });
    }
}
