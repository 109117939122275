import { PaymentOptionModel } from "./payment-option.model";
import { PaymentMethodModel } from "./payment-method.model";

export class ClaimRefundModel {
    paymentMethod: PaymentMethodModel;
    paymentOption: PaymentOptionModel;
    transactionId: string;
    amountPaid: number;
    amountRefund: number;

    constructor() {
        this.paymentMethod = new PaymentMethodModel();
        this.paymentOption = new PaymentOptionModel();
        this.transactionId = "";
        this.amountPaid = 0;
        this.amountRefund = 0;
    }
}
