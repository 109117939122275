<div class="payment-nav-wrapper d-flex">
    <div
        class="payment-nav-item flex-fill {{ _selectedNavigationItem === paymentNavigationItems.SCAN ? 'active': null }}"
        (click)="onSwitchPaymentContent(paymentNavigationItems.SCAN)">{{ 'scan_btn' | translate }}
    </div>
    <div
        class="payment-nav-item flex-fill {{ _selectedNavigationItem === paymentNavigationItems.MANUAL ? 'active': null }}"
        (click)="onSwitchPaymentContent(paymentNavigationItems.MANUAL)">{{ 'manual_btn' | translate }}
    </div>
</div>
