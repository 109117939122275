import { PaymentNetworkModel } from './payment-network.model';

export class PaymentOptionModel {
  code: string;
  section: string;
  name: string;
  order_by: number;
  payment_networks: PaymentNetworkModel[];
  kyc_required: boolean;
  powered_by: string;

  constructor() {
    this.code = '';
    this.section = '';
    this.name = '';
    this.order_by = 0;
    this.payment_networks = [];
    this.kyc_required = false;
    this.powered_by = '';
  }
}
