import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ChargeComponent } from "./components/charge/charge.component";
import { TermsComponent } from "./pages/terms/terms.component";
import { CookiesComponent } from "./pages/cookies/cookies.component";
import { NotFoundComponent } from "./shared/components/errors/not-found/not-found.component";

const routes: Routes = [
    {
        path: "charge/:id",
        component: ChargeComponent,
    },
    {
        path: "terms",
        component: TermsComponent,
    },
    {
        path: "cookies",
        component: CookiesComponent,
    },
    {
        path: "404",
        component: NotFoundComponent,
    },
    { path: "**", redirectTo: "404" },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
