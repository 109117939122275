export class KYCModel {
    email: string;
    first_name: string;
    last_name: string;
    nationality: string;
    country: string;
    city: string;
    zip_code: string;
    address: string;
    building_number?: string;
    terms: boolean;

    constructor() {
        this.email = "";
        this.first_name = "";
        this.last_name = "";
        this.nationality = "";
        this.country = "";
        this.city = "";
        this.zip_code = "";
        this.address = "";
        this.terms = false;
    }
}
