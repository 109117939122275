import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class UtilsService {
    isAppReady$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoadingError$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoaderShown$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isContentLoaderShown$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isBoxLoaderShown$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isMobile$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isMainFooterShown$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    urlParams$$: BehaviorSubject<object> = new BehaviorSubject<object>({});
    isTerminatePaymentShown$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isContentTerminatePaymentShown$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    skipUnloadConfirm$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor() {}

    setAppToReady() {
        this.isAppReady$$.next(true);
        this.showTerminatePayment();
    }

    showLoadingError() {
        this.isLoadingError$$.next(true);
    }

    hideLoadingError() {
        this.isLoadingError$$.next(false);
    }

    showLoader() {
        this.isLoaderShown$$.next(true);
    }

    hideLoader() {
        this.isLoaderShown$$.next(false);
    }

    showContentLoader() {
        this.isContentLoaderShown$$.next(true);
    }

    hideContentLoader() {
        this.isContentLoaderShown$$.next(false);
    }

    showBoxLoader() {
        this.isBoxLoaderShown$$.next(true);
    }

    hideBoxLoader() {
        this.isBoxLoaderShown$$.next(false);
    }

    showTerminatePayment() {
        this.isTerminatePaymentShown$$.next(true);
    }

    hideTerminatePayment() {
        this.isTerminatePaymentShown$$.next(false);
    }

    showContentTerminatePayment() {
        this.isContentTerminatePaymentShown$$.next(true);
    }

    hideContentTerminatePayment() {
        this.isContentTerminatePaymentShown$$.next(false);
    }

    getUTCTimestamp() {
        let now = new Date();
        let nowUTC = Date.UTC(
            now.getUTCFullYear(),
            now.getUTCMonth(),
            now.getUTCDate(),
            now.getUTCHours(),
            now.getUTCMinutes(),
            now.getUTCSeconds()
        );
        return parseInt(nowUTC.toString().slice(0, -3));
    }

    timeDifference(timestamp1: number, timestamp2: number) {
        return timestamp1 - timestamp2;
    }

    parseTime(time: number) {
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        return `${minutes}:${(seconds < 10 ? "0" : "") + seconds}`;
    }

    modifyUrl(url: string, params: string) {
        if (url.indexOf("?") !== -1) {
            url += "&";
        } else {
            url += "?";
        }
        return `${url}${params}`;
    }

    getBaseUrl(): string | null {
        const result: RegExpMatchArray | null = location.origin.match(
            "http(s)?:\\/\\/(?<host>[A-Za-z0-9.-]*)((:\\d*))?(.*)?"
        );
        const groups = result ? result.groups : null;
        if (groups == null || !("host" in groups)) {
            return null;
        }

        return groups.host;
    }

    setDocumentProperty(propertyName: string, propertyValue: string) {
        const _document = document.documentElement;
        _document.style.setProperty(propertyName, propertyValue);
    }
}
