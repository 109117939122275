import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { HttpLoaderFactory } from "../app.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LottieModule } from "ngx-lottie";

import { LoaderComponent } from "./components/loader/loader.component";
import { ContentLoaderComponent } from "./components/content-loader/content-loader.component";
import { NotFoundComponent } from "./components/errors/not-found/not-found.component";
import { HeaderComponent } from "./components/header/header.component";
import { AlertComponent } from "./components/alert/alert.component";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { FooterComponent } from "./components/footer/footer.component";
import { PreviousStepComponent } from "./components/previous-step/previous-step.component";
import { environment } from "../../environments/environment";
import player from "lottie-web";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { BoxLoaderComponent } from "./components/box-loader/box-loader.component";

export function playerFactory() {
    return player;
}

@NgModule({
    declarations: [
        LoaderComponent,
        ContentLoaderComponent,
        AlertComponent,
        NotFoundComponent,
        HeaderComponent,
        FooterComponent,
        PreviousStepComponent,
        BoxLoaderComponent,
    ],
    imports: [
        CommonModule,
        NgbModule,
        RouterModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.defaultLanguage,
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        FontAwesomeModule,
        LottieModule.forRoot({
            player: playerFactory,
        }),
        ClipboardModule,
    ],
    exports: [
        ClipboardModule,
        LoaderComponent,
        ContentLoaderComponent,
        AlertComponent,
        NotFoundComponent,
        HeaderComponent,
        FooterComponent,
        PreviousStepComponent,
        BoxLoaderComponent,
    ],
})
export class SharedModule {}
