import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocaleService } from '../../../core/services/locale.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HelperService } from '../../../core/services/helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  logo = this.helperService.getDocumentProperty('--header-logo-url');
  languages: any;

  constructor(
    private helperService: HelperService,
    public localeService: LocaleService,
    public sanitizing: DomSanitizer
  ) {
    // set languages
    this.subscriptions.push(
      this.localeService.languages$$.subscribe({
        next: (languages) => {
          this.languages = languages;
        }
      })
    );
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  onLangChange(lang: any) {
    this.localeService.setLocale(lang);
  }
}
