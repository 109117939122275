import { Injectable, Optional, SkipSelf } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, noop } from "rxjs";

type ShouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) => boolean;

@Injectable({
    providedIn: "root",
})
export class LocaleService {
    languages$$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    private initialized = false;

    get currentLocale(): string {
        return this.translate.currentLang;
    }

    constructor(
        private router: Router,
        private translate: TranslateService,
        @Optional()
        @SkipSelf()
        otherInstance: LocaleService
        // private http: HttpClient,
        // private errorService: ErrorService
    ) {
        if (otherInstance) throw "LocaleService should have only one instance.";
    }

    private setRouteReuse(reuse: ShouldReuseRoute) {
        this.router.routeReuseStrategy.shouldReuseRoute = reuse;
    }

    private subscribeToLangChange() {
        // it refresh app everytime user change language
        this.translate.onLangChange.subscribe(async () => {
            const { shouldReuseRoute } = this.router.routeReuseStrategy;

            this.setRouteReuse(() => false);
            this.router.navigated = false;

            await this.router.navigateByUrl(this.router.url).catch(noop);
            this.setRouteReuse(shouldReuseRoute);
        });
    }

    initLocale(localeId: string, defaultLocaleId = localeId) {
        if (this.initialized) return;

        this.setDefaultLocale(defaultLocaleId);
        this.setLocale(localeId);
        // if you want to refresh page
        // this.subscribeToLangChange();

        this.initialized = true;
    }

    setDefaultLocale(localeId: string) {
        this.translate.setDefaultLang(localeId);
    }

    getDefaultLocale() {
        return this.translate.getDefaultLang();
    }

    setLocale(localeId: string) {
        this.translate.use(localeId);
    }
}
