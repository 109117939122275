import { Component, OnDestroy, OnInit } from "@angular/core";
import { TenantService } from "../../../core/services/tenant.service";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];

    currentLang: string;
    currentDate: Date;
    configData: any;
    termsOfUse = null;
    privacyPolicy = null;
    cookies = null;
    poweredBy = null;
    supportEmail = null;

    constructor(private tenantService: TenantService, private translate: TranslateService) {
        // set current date
        this.currentDate = new Date();

        // get tenant config data
        this.configData = tenantService.config$$.value;

        // set default language
        this.currentLang = environment.defaultLanguage;

        // set footer data
        this.setFooterData();

        // subscribe to language change
        this.subscriptions.push(
            translate.onLangChange.subscribe((lang) => {
                // set current language
                this.currentLang = lang.lang;
                // set footer data
                this.setFooterData();
            })
        );
    }

    ngOnInit() {}

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    setFooterData() {
        // reset data
        this.termsOfUse = null;
        this.privacyPolicy = null;
        this.cookies = null;
        this.supportEmail = null;
        // get data
        const documents = this.configData.documents ?? null;
        const supportEmails = this.configData.support_emails ?? null;
        // set documents
        if (documents) {
            const lang = this.currentLang in documents ? this.currentLang : environment.defaultLanguage;
            if (lang in documents) {
                this.termsOfUse = documents[lang].terms_of_use ?? null;
                this.privacyPolicy = documents[lang].privacy_policy ?? null;
                this.cookies = documents[lang].cookies ?? null;
            }
        }
        // set support email
        if (supportEmails) {
            const lang = this.currentLang in supportEmails ? this.currentLang : environment.defaultLanguage;
            if (lang in supportEmails) {
                this.supportEmail = supportEmails[lang];
            }
        }
        // set powered by
        this.poweredBy = this.configData.powered_by ?? null;
    }
}
