import { Component, Input, OnInit } from '@angular/core';
import { StepModel } from '../../core/models/step.model';
import { Subscription } from 'rxjs';
import { paymentSteps } from '../../core/services/step.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html'
})
export class PaymentComponent implements OnInit {
  subscriptions: Subscription[] = [];

  @Input() currentStep: StepModel | undefined;
  paymentSteps = paymentSteps;

  constructor() {}

  ngOnInit() {}
}
