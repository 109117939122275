import { Component, OnInit, Renderer2 } from "@angular/core";
import { UtilsService } from "../../../../core/services/utils.service";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperService } from "../../../../core/services/helper.service";
import { paymentStatuses } from "../../../../core/services/payment.service";
import { AnimationOptions } from "ngx-lottie";

@Component({
    selector: "app-not-found",
    templateUrl: "./not-found.component.html",
    styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
    icon = "";
    iconOptions: AnimationOptions = {
        path: "/assets/loader.json",
        loop: false,
    };

    constructor(
        private renderer: Renderer2,
        private utilsService: UtilsService,
        private helperService: HelperService,
        public sanitizing: DomSanitizer
    ) {
        this.utilsService.hideLoader();
        this.renderer.removeClass(document.body, "gradient");
        this.renderer.addClass(document.body, "default");
        this.renderer.addClass(document.body, "bg-grey");
        this.utilsService.isMainFooterShown$$.next(true);
    }

    ngOnInit() {
        this.icon = this.helperService.paymentStatusesIcons[paymentStatuses.EXPIRED];
        this.iconOptions = {
            ...this.iconOptions,
            path: this.helperService.paymentStatusesIcons[paymentStatuses.EXPIRED],
        };
    }
}
