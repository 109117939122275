<div class="store-name">{{ charge.shop_name }}</div>
<div class="amount">
    {{ currencyDisplaySymbol }}&nbsp;{{ (charge.amount / 100) | currency: currencyCode:'':'1.2-2' }}
    <div *ngIf="isMobile && charge.items && charge.items.length > 0"
         class="items-list-btn {{ isItemsDetailsShown ? 'open' : '' }}"
         (click)="onItemsListToggle()">{{ isItemsDetailsShown ? ('close_btn' | translate) : ('details_btn' | translate) }}</div>
    <span class="tax-info-text">{{ 'charge_details.tax_info' | translate }}</span>
</div>
<div class="divider"></div>
<div *ngIf="charge.items && charge.items.length > 0 && isItemsDetailsShown" class="items-list">
    <div *ngFor="let chargeDetailItem of charge.items; let i = index"
         class="d-flex align-items-center item-wrapper">
        <div class="flex-shrink-0">
            <div class="item-image"><span>{{ i + 1 }}.</span></div>
        </div>
        <div class="flex-grow-1 ms-3">
            <div class="item-name">
                {{ chargeDetailItem.name }}
                <span class="float-end">
                    {{ currencyDisplaySymbol }}&nbsp;{{ ((chargeDetailItem.quantity > 1 ? chargeDetailItem.price * chargeDetailItem.quantity : chargeDetailItem.price) / 100) | currency: currencyCode:'':'1.2-2' }}
                </span>
            </div>
            <div class="item-qty">
                <span class="item-qty__number">
                    {{ 'charge_details.qty' | translate }} {{ chargeDetailItem.quantity }}
                </span>
                <span class="item-qty__price" *ngIf="chargeDetailItem.quantity > 1">
                    {{ currencyDisplaySymbol }}&nbsp;{{ (chargeDetailItem.price / 100) | currency: currencyCode:'':'1.2-2' }} {{ 'charge_details.each' | translate }}
                </span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="charge.items && charge.items.length > 0 && isItemsDetailsShown" class="divider"></div>
<div *ngIf="isTerminatePaymentShown" class="terminate-payment-wrapper">
    <button type="button" (click)="onTerminatePayment()" class="btn btn-link btn-link-primary btn-cancel-payment">
        {{ 'payment.terminate_payment_btn' | translate }}
    </button>
</div>
